// global styles
import '../styles/settings.scss'
//icons
import { mdi } from 'vuetify/iconsets/mdi-svg'

import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components';
import * as directives from 'vuetify/lib/directives'
import * as labsComponents from 'vuetify/labs/components';
import { md3 } from 'vuetify/blueprints'

export default createVuetify(
  {
    blueprint: md3,
    components,
    directives,
    icons: {
      defaultSet: 'mdi',
      sets: {
        mdiSvg: mdi,

      },
    },
    theme: {
      defaultTheme: 'dark',
      themes: {
        light: {
          colors: {
            'surface-variant-alt': '#dedede',
            primary: '#1867c0',
            secondary: '#5CBBF6',
            tertiary: '#E57373',
            accent: '#005CAF',
            quarternary: '#B0D1E8',
            'surface-bright': '#fafafa',
          },
        },
        dark: {
          colors: {
            background: 'hsl(228, 43%, 12%)', // main background, overiden by css so we can use gradient
            surface: 'hsl(222, 19%, 10%)', // background for cards etc
            'surface-darken-1': 'hsl(240, 25%, 4%)', // used in row hover
            'on-surface': '#ffffff', // text on this surface
            'surface-variant': 'hsl(175, 80%, 23%)', // color for switch, tooltips
            'on-surface-variant': '#ffffff',
            primary: 'hsl(175, 80%, 41%)',
            'primary-darken-1': 'hsl(175, 60%, 28%)', // used in button gradients
            'primary-faded': 'hsl(180, 80%, 11%)', // used for selected rows and background on login inputs
            'blue-darken-1': 'hsl(210, 50%, 35%)', // used in button hover background gradient
            'blue-darken-2': 'hsl(210, 50%, 25%)', // used in list hover background gradient
            'on-primary': 'hsl(222, 19%, 10%)', // should match surface
            secondary: 'hsl(222, 19%, 10%)', // should match surface
            accent: '#FF4081',
            quarternary: 'hsl(200, 30%, 13%)', // used for toolbar color
            'on-quarternary': '#ffffff',
            error: 'hsl(355, 70%, 45%)',
            'error-darken-1': 'hsl(355, 70%, 35%)', // used for background gradient
            'error-darken-2': 'hsl(355, 70%, 25%)', // used for background hover gradient
            warning: 'hsl(395, 80%, 45%)',
            success: 'hsl(125, 80%, 45%)',
            disabled: 'hsl(203, 15%, 20%)', // used for rows of non tracked products
            link: 'hsl(205, 100%, 50%)',
            'link-visited': 'hsl(310, 40 %, 55 %)'
          },
          variables: {
            'hover-opacity': 0,
          }
        },
      }
    },
    defaults: {
      VAppBar: {
        color: "surface",
        height: "50"
      },
      VAutocomplete: {
        hideDetails: true,
        variant: "outlined",
        clearable: true
      },
      VBtn: {
        variant: "elevated",
        color: "primary",
        rounded:"sm"
      },
      VCard: {
        elevation: 24
      },
      VCardActions: {
        VBtn: {
          variant: "elevated",
        }
      },
      VCheckbox: {
        hideDetails: true,
      },
      VContainer: {
        fluid: true
      },
      VDataTable: {
        VCheckbox: {
          hideDetails: true,
          color: "primary",
          class: "text-medium-emphasis"
        },
        VSelect: {
          density: "comfortable"
        },
      },
      VDialog: {
        transition: "dialog-bottom-transition",
        maxWidth: "500"
      },
      VFileInput: {
        color: "primary",
        class: "text-primary",
        chips: true,
        multiple: true,
        counter: true,
        'show-size': true,
        variant: "outlined",
      },
      VImg: {
        position: "center center"
      },
      VProgressLinear: {
        color: "primary",
      },
      VSelect: {
        class: "text-primary",
        color: "primary",
        variant: "outlined",
        hideDetails: true,
      },
      VSkeletonLoader: {
        class: "mx-auto"
      },
      VSnackbar: {
        timeout: "3000"
      },
      VTab: {
        class:"bg-quarternary"
      },
      VTextField: {
        color: "primary",
        class: "text-primary",
        variant: "outlined",
      },
      VToolbar: {
        color:"quarternary",
        flat: true,

        VTextField: {
          density:"comfortable",
          singleLine: true,
          hideDetails: true,
        }
      },
      VTooltip: {
        location: "top"
      }
    },
  }
)
