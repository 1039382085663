<template >
  <v-container fluid class="">
    <v-card>
      <v-toolbar title="Category loads"> </v-toolbar>
      <v-container class="d-flex flex-column">
        <v-row>
          <v-col cols="3">
            <v-select
              label="Select a category"
              :items="availableCategories"
              item-title="text"
              item-value="value"
              v-model="selectedCategory"
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-select
              label="Select a competitor"
              :items="availableCompetitors"
              item-title="domain_name"
              item-value="domain_id"
              v-model="selectedCompetitor"
            ></v-select>
          </v-col>
          <v-col class="align-self-center">
            <v-btn @click="showSpecs" :disabled="disable_show_specs" size="large" append-icon="mdi-chevron-down" :loading="loadingSpecifications">Show specifications</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card title="New specifications" class="mt-5" v-show="specificationsLoaded || loadingSpecifications">
      <v-skeleton-loader
        class="mx-auto"
        height="400"
        type="table-row, table-row, table-row, table-row"
        v-show="loadingSpecifications"
      ></v-skeleton-loader>
      <v-row class="pa-5 pr-10">
        <v-col cols="3" v-for="spec in categorySpecs" v-show="specificationsLoaded">
          <v-row>
            <v-col cols="6" class="align-self-center d-flex justify-end">
              <span >{{ spec.spec_name }}: </span>
            </v-col>
            <v-col cols="6">
              <v-select
                label="Weight"
                :items="specWeights"
                item-title="name"
                item-value="value"
                v-model="spec.weight"
                density="compact"
              ></v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <div class="d-flex mt-5">
      <v-spacer></v-spacer>
      <v-btn prepend-icon="mdi-cloud-upload" size="x-large" rounded="lg" @click="saveSpecs" width="400" v-show="specificationsLoaded">Save new specifications</v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      specWeights: [
        {name: 'Ignored', value: 0},
        {name: 'Low', value: 0.5},
        {name: 'Default', value: 1},
        {name: 'High', value: 2},
        {name: 'Exceptional', value: 4}]
    };
  },
  computed: {
    availableCompetitors: function() {
      return this.$store.state.competitorsModule.competitors;
    },
    availableCategories: function() {
      return this.$store.state.categoryModule.availableCategories;
    },
    selectedCompetitor: {
      get () {
        let competitorID = this.$store.state.categoryModule.selectedCompetitor;
        return (competitorID > 0) ? competitorID : null;
      },
      set (value) {
        this.$store.commit('categoryModule/setCompetitor', value);
      }
    },
    selectedCategory: {
      get () {
        let categoryID = this.$store.state.categoryModule.selectedCategory;
        return (categoryID > 0) ? categoryID : null;
      },
      set (value) {
        this.$store.commit('categoryModule/setCategory', value);
      }
    },
    categorySpecs: {
      get () {
        return this.$store.state.categoryModule.categorySpecs;
      },
      set (value) {
        this.$store.commit('categoryModule/setCategorySpecs', value);
      }
    },
    disable_show_specs: function() {
      return !(this.selectedCategory && this.selectedCompetitor);
    },
    loadingSpecifications: function() {
      return this.$store.state.categoryModule.loadingSpecifications;
    },
    specificationsLoaded: function() {
      return this.$store.state.categoryModule.specificationsLoaded;
    },
  },
  methods: {
    showSpecs () {
      this.$store.dispatch("categoryModule/getCategorySpecs");
    },
    saveSpecs () {
      console.log('saving specs');
    }
  },
  created() {
    this.$store.dispatch("competitorsModule/LoadCompetitors");
    this.$store.dispatch("categoryModule/getCategories");
  },
};
</script>

<style lang="scss">

</style>