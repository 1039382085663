/*eslint-disable*/
import axios from "axios";
import JwtService from "./JwtService";

const isProd = process.env.NODE_ENV === 'production' ? 'production' : 'develop'
export const AppUrl = `http://chasma.eu/client_console/${isProd}/console/api/similarController.php`

export const guest_api = axios.create({
  method: 'POST',
  url: AppUrl,
  headers: {
    // Origin: AppUrl,
    'content-type': "application/x-www-form-urlencoded",
  },
  auth: {
    username: `inforderclient`,
    password: `infoRd3r`,
  }
})
