<template>
  <v-card title="My Profile" class="ma-5" max-width="600">
    <v-container>
    <v-text-field label="Username" v-model="user.username" disabled></v-text-field>
    <v-text-field label="Email" v-model="user.email"></v-text-field>
    <v-text-field label="First Name" v-model="user.firstname"></v-text-field>
    <v-text-field label="Last Name" v-model="user.lastname"></v-text-field>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn :loading="change_pass_loader" @click="pass_dialog = true">Reset password</v-btn>
      <v-btn :loading="save_loader" @click="saveInfos">Update</v-btn>
    </v-card-actions>
  </v-container>
  </v-card>

  <v-dialog v-model="pass_dialog">
      <v-card title="Reset password">
        <v-card-text>
          <v-text-field v-model="passwords.old" label="Old Password" type="password"></v-text-field>
          <v-text-field v-model="passwords.new" label="New Password" type="password"></v-text-field>
          <v-text-field v-model="passwords.confirm" label="Confirm Password" type="password"></v-text-field>
          <div class="d-flex flex-column mx-2 my-2">
            <span :class="{'text-green': is_new_pw_8to16}">
              <v-icon icon="mdi-close" v-show="!is_new_pw_8to16"></v-icon>
              <v-icon icon="mdi-check" v-show="is_new_pw_8to16"></v-icon>
              Password should be at least 8 characters long
            </span>
            <span :class="{'text-green': pw_confirm}">
              <v-icon icon="mdi-close" v-show="!pw_confirm"></v-icon>
              <v-icon icon="mdi-check" v-show="pw_confirm"></v-icon>
              Please retype your new password to confirm it
            </span>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="cancel_change_password">Cancel</v-btn>
            <v-btn @click="changePassword" :disabled="!allow_update_pw">Update Password</v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar.show">
      {{ snackbar.message }}
    </v-snackbar>
</template>

<script>
import axios from "axios";
import { AppUrl } from "@/helpers/variables";
import { empty_object } from "@/assets/js/myFunctions";
import JwtService from '@/assets/js/JwtService'
export default {
  name: "user-profile-page",
  data() {
    return {
      user: {
        username: '',
        email: '',
        firstname: '',
        lastname: '',
      },
      change_pass_loader: false,
      save_loader: false,
      pass_dialog: false,
      passwords: {
        old: '',
        new: '',
        confirm: '',
      },
      snackbar:{
        show: false,
        message: '',
      },
    };
  },
  computed: {
    is_old_pw_8to16 () {
      return this.passwords.new.length >= 8 && this.passwords.new.length <= 16
    },
    is_new_pw_8to16 () {
      return this.passwords.new.length >= 8 && this.passwords.new.length <= 16
    },

    pw_confirm () {
      return (this.passwords.confirm === this.passwords.new) && this.is_new_pw_8to16;
    },
    allow_update_pw () {
      return this.is_old_pw_8to16
          && this.pw_confirm;
    },
  },
  methods: {
    async getUser() {
      // return
      const payload = { task: "getMyProfileInfos" };
      const params = { headers: { Authorization: `Bearer ${JwtService.getToken()}` } }
      const { data } = await axios.post(AppUrl, payload, params )
      this.user = data.user
    },
    async changePassword() {
      this.change_pass_loader = true
      const payload = { task: "userChangePassword", payload: this.passwords };
      const params = { headers: { Authorization: `Bearer ${JwtService.getToken()}` } }
      try {
        const { data } = await axios.post(AppUrl, payload, params )
        this.open_snackbar(data.message);
      } catch (error) {
        console.log(' userChangePassword error', error)
      } finally {
        this.change_pass_loader = false
      }
    },
    async saveInfos() {
      this.save_loader = true
      const payload = { task: "updateUser", payload: this.user };
      const params = { headers: { Authorization: `Bearer ${JwtService.getToken()}` } }
      try {
        const { data } = await axios.post(AppUrl, payload, params )
        this.user = data.user;
        this.open_snackbar(data.message);
      } catch (error) {
        console.log(' userChangeInfos error', error)
      } finally {
        this.save_loader = false
      }
    },
    cancel_change_password() {
      this.passwords = empty_object(this.passwords);
      this.pass_dialog = false;
    },
    open_snackbar(msg) {
      this.snackbar.message = msg;
      this.snackbar.show = true;
    }
  },
  mounted() {
    this.getUser();
  },
};
</script>