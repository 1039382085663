/* eslint-disable */
import axios from "axios";
import { AppUrl } from "@/helpers/variables";
import JwtService from "@/assets/js/JwtService";
import { empty_object } from "@/assets/js/myFunctions";

const state = {
  loading: false,
  show_no_products_dialog: false,
  competitors: [],
  selected_competitor: {
    domain_id: "",
    domain_name: "",
  },
  competitor_table: {
    products: [],
    specs: [],
    displayed_ids: [],
  },
  show_matching_table: false,
  products: [],
  categories: [],
};

const getters = {
};

const mutations = {
  SET_LOADING(state, value) {
    state.loading = value;
  },
  CLEAR_DATA( state ) {
    state.products = { categories: [], title: [], sku: [], }
    state.competitor_table = { products: [], specs: [], displayed_ids: [], }
    state.show_matching_table = false
    state.products = []
  },
  updateCompetitor(state, payload ) {
    state.selected_competitor.domain_id = payload;
    const name = state.competitors.find(n => n.domain_id == payload).domain_name;
    state.selected_competitor.domain_name = name;
  },
  SET_SELECTED_COMPETITOR( context, payload ) {
    context.selected_competitor = payload ;
    context.show_matching_table = false;
  },
  SET_PRODUCTS( state, products ) {
    state.competitor_table.products = [];
    state.competitor_table.products = products;
  },
  SET_SHOW_NO_PRODUCTS_DIALOG(state, value) {
    state.show_no_products_dialog = value;
  },
  SET_CATEGORIES(state, payload) {
    state.categories = payload;
  },
  EMPTY_MODULE(state) {
    state.loading = false
    state.competitors = []
    state.selected_competitor = empty_object(state.selected_competitor)
    state.competitor_table = empty_object(state.competitor_table)
    state.selected_suggestions = []
    state.show_matching_table = false
    state.products = []
    state.categories = []
    state.show_no_products_dialog = false;
  },
  SET_COMPETITORS(state, value) {
    state.competitors = value;
  }
};

const actions = {
  LoadCompetitors(context) {
    const params = { headers: { Authorization: `Bearer ${JwtService.getToken()}` } }
    if( (Array.isArray(context.state.competitors) && context.state.competitors.length < 1) )
    {
      return new Promise((resolve, reject) => {
        axios
          .post(AppUrl, {
            task: "loadCompetitorsName",
            payload: {
              domain_id: '13326', //context.selected_competitor.domain_id
            }
          }, params)
          .then(function (response) {
            // we need commit here, otherwise competitor module doesn't know it has changed
            context.commit('SET_COMPETITORS', response.data.competitors);
            resolve(response);
          })
          .catch((err) => {
            context.commit('authModule/logout');
            reject(err)
          });
      });
    }
  },
  SubmitFilter(context, payload) {
    const params = { headers: { Authorization: `Bearer ${JwtService.getToken()}` } }
    context.state.loading = true;
    return new Promise((resolve, reject) => {
      axios
        .post(AppUrl, {
          task: "loadCompetitorProductsV2",
          payload: {
            domain_id: context.rootState.authModule.client.domain_id,//'13326',
            filters: payload,
            competitor_domain_id: context.state.selected_competitor.domain_id,
            selected_item_id: context.rootState.clientModule.selected_item_id,
          }
        }, params)
        .then(function (response) {
          context.commit('SET_LOADING', false);
          if (response.data.payload.products == null) {
            context.commit('SET_SHOW_NO_PRODUCTS_DIALOG', true);
          } else {
            context.commit('SET_PRODUCTS', response.data.payload.products);
          }
          resolve(response);
        })
        .catch((err) => {
          console.log('error', err)
          context.commit('authModule/logout');
          reject(err)
        });
    });
  },
  // this is used in categories amtching
  // this is getting a very long empty array of values?
  GET_CATEGORIES({ state, commit }) {
    commit('SET_CATEGORIES', [])
    const data = {
      task: 'get_raw_categories',
      payload: {
        domain_id: state.selected_competitor.domain_id
      }
    }
    const params = { headers: { Authorization: `Bearer ${JwtService.getToken()}` } }
    return new Promise((resolve) => {
      axios
        .post(AppUrl, data, params)
        .then(function (response) {
          const categories = response.data.categories
          commit('SET_CATEGORIES',categories)
          resolve(response);
        })
        .catch((err) => {
          console.log('error', err)
          commit('authModule/logout', null, { root: true });
          reject(err)
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
