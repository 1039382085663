<template>
  <v-container class="pb-5 pt-0">
    <!-- we use clearable -->
    <v-autocomplete
      label="SKU"
      v-model="sku"
      :items="fetchedSkus"
      item-value="sku"
      @update:search="lookForSku($event)"
      @update:modelValue="selectSKU"
      :loading="loading"
    />
    <v-container v-if="$store.state.clientModule.hasItem" >
      <v-img
        position="start center"
        class="d-flex child-flex shrink mx-auto"
        alt="product image"
        :src="productImage"
        transition="scale-transition"
        height="257"
      />
      <v-table>
        <template v-slot:default>
          <tbody>
            <tr>
              <td class="spec-title">SKU : </td> <td>{{ selectedClientsItem.sku }}</td>
            </tr>
            <tr>
              <td class="spec-title">Title :</td>
              <td class="overflowing-text--single spec-value">
                <v-tooltip>
                  <template v-slot:activator="{ props }" >
                    <span v-bind="props"> {{ selectedClientsItem.brand_title }} </span>
                  </template>
                  <span>{{selectedClientsItem.brand_title}}</span>
                </v-tooltip>
              </td>
            </tr>
            <tr>
              <td class="spec-title">Price :</td> <td>{{ selectedItemPrice }}</td>
            </tr>
          </tbody>
        </template>
      </v-table>
    </v-container>
    <v-container v-else-if="loading">
      <v-skeleton-loader max-width="300" type="card"></v-skeleton-loader>
    </v-container>
    <div class="text-center" v-if="$store.state.clientModule.hasItem">
      <v-btn prepend-icon="mdi-information" @click="openDialog">MORE INFORMATION</v-btn>
    </div>
    <!-- more information dialog -->
    <v-dialog v-model="dialog" max-width="800px">
      <v-card>
        <v-toolbar :title="selectedClientsItem.title"> </v-toolbar>
        <v-card-text>
          <v-img alt="product image" height="300" :src="productImage" transition="scale-transition"/>
          <v-table>
            <template v-slot:default>
              <tbody>
                <tr v-for="(item, index) in selectedClientsItem" :key="index">
                  <td v-if="!!item && index !== 'specs'">{{ index }}</td>
                  <td v-if="!!item && index !== 'specs'">{{ item }}</td>
                </tr>
                <tr v-for="(value,propKey,spec_idx) in selectedClientsItem.specs" :key="spec_idx">
                  <td>{{ propKey }}</td>
                  <td>{{ value }}</td>
                </tr>
              </tbody>
            </template>
          </v-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialog= false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { formatNumber } from "@/helpers/commonFunctions.js"; // these also need to go to the methods

  export default {
    name: 'selected-product',

    data: () => ({
      sku: null,
      dialog: false,
    }),
    computed: {
      loading () {
        return this.$store.state.clientModule.loader
      },
      selectedClientsItem () {
        return this.$store.state.clientModule.selected_item;
      },
      fetchedSkus() {
        return this.$store.state.clientModule.skus.map(i => (Object.assign(i, {title: `${i.sku}: ${i.title}`})));
      },
      productImage () {
        if(this.$store.state.clientModule.hasItem){
          return this.$store.state.clientModule.selected_item.image;
        }
        return require('@/assets/images/default_product.jpg');
      },
      selectedItemPrice () {
        let price = '';
        if (this.selectedClientsItem.price > 0) {
          price = formatNumber(this.selectedClientsItem.price) + ' €';
        }
        return price;
      }
    },
    methods: {
      lookForSku (ev) {
        const written_text = ev;
        if(!!written_text && written_text.length>1){
          this.$store.dispatch('clientModule/clientSkus', written_text);
        }
      },
      selectSKU () {
        this.$store.commit('competitorsModule/CLEAR_DATA');
        this.$store.commit('clientModule/CLEAR_DATA');
        this.$store.dispatch('clientModule/selectSku', this.sku);
      },
      openDialog () {
        this.dialog = true;
      },
      formatNumber
    }
  }
</script>

<style lang="scss">
.spec-title {
  width: 60px;
  padding:0 !important;
}
.spec-value.overflowing-text--single {
  max-width: 200px !important;
  @media (min-width: 1280px) {
    max-width: 150px !important;
    }
  @media (min-width: 1700px) {
    max-width: 200px !important;
  }
}
</style>
