<template>
<v-container>
  <div class="text-h6 ms-5 my-5">Category Matching</div>
  <v-row>
    <v-col sm='12' md='5' offset-md="1">
      <v-card>
        <v-select
          v-model="selected_category"
          label="Select Category"
          :items="client_categories"
          :menu-props="{ bottom: true, offsetY: true }"
          variant="solo"
          class="mt-3"
          :loading="client_categories.length == 0"
        ></v-select>
      </v-card>
    </v-col>
    <v-col sm='12' md='3' offset-md="1"><CompetitorSelect menu_expand="bottom" @change_competitor="change_competitor" /></v-col>
  </v-row>
  <v-data-table
    v-model="selected_competitor_category"
    :headers="headers"
    :items="competitor_categories"
    :search="selectFor"
    show-select
    class="elevation-1 mx-10"
    :loading="competitor_categories.length == 0 && selected_competitor_id != 0"
    loading-text="Loading Products... Please wait"
    v-if="selected_competitor_id != 0"
    item-key="text"
  ></v-data-table>
  <!-- <v-row v-if="selected_competitor_id != 0">
    <v-col sm='12' md='5' offset-md="1">
      <v-card>
        <v-select
            v-model="selected_competitor_category"
            label="Select Competitor Category"
            :items="competitor_categories"
            :menu-props="{ bottom: true, offsetY: true }"
            solo
            class="mt-3"
            hide-details
            :loading="competitor_categories.length == 0 && selected_competitor_id != 0"
        ></v-select>
      </v-card>
    </v-col>
    <v-col sm='12' md='3' offset-md="1">
      <v-btn>Save</v-btn>
    </v-col>
  </v-row> -->
  <v-divider class="my-7"></v-divider>
  <!-- <v-row >

  </v-row> -->

</v-container>
</template>

<script>
import CompetitorSelect from "@/components/Competitor.vue"

export default {
  name: 'Category-Matching-View',
  components: { CompetitorSelect },
  data: () => ({
    selected_category: undefined,
    selected_competitor_category: [],
    selectFor: "",
    // client_categories: [
    //   {
    //     name: 'Domains',
    //     value: 'domains',
    //   }
    // ],
    // competitor_categories: [],
    file_data: {},
    headers: [{ text: 'Category', value: "text" },]
  }),
  computed: {
    client_categories() {
      return this.$store.state.clientModule.categories;
    },
    selected_competitor_id() {
      return this.$store.state.competitorsModule.selected_competitor.domain_id;
    },
    competitor_categories() {
      return this.$store.state.competitorsModule.categories.map((cat) => ({text: cat, value:cat}));
    }
  },
  methods: {
    change_competitor() {
      this.getCompetitorCategories();
      this.open_json();
    },
    getCompetitorCategories() {
      this.$store.dispatch('competitorsModule/GET_CATEGORIES')
    },
    open_json() {
      let json = require(`../assets/json/${this.selected_competitor_id}.json`);
      console.log('open json', json)
      this.file_data = json;
      // fetch(`/src/assets/json/${this.selected_competitor_id}.json`)
      //   .then((response) => response.json())
      //   .then((json) => {console.log('json',json); this.file_data = json });
      // this.file_data = JSON.parse()
    }
  },
  mounted () {
    this.$store.dispatch('clientModule/GET_CATEGORIES')
  },
}
</script>
