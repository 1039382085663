import { createStore } from 'vuex'

import authModule from './modules/auth'
import competitorsModule from './modules/competitorsModule'
import clientModule from './modules/clientModule'
import categoryModule from './modules/categoryModule'

const store = createStore({
  modules: {
    competitorsModule,
    clientModule,
    authModule,
    categoryModule
  }
})

export default store;
