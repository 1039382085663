import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/index'
import Login from '../views/Login.vue'
import Suggestions from '../views/Suggestions.vue'
import Import from '../views/Import.vue'
import MatchingCategories from '@/views/CategoryMatching.vue'
import Matches from '@/views/Matches.vue'
import UsersAdministration from '@/views/UsersAdministration.vue'
import UserProfile from '@/views/UserProfile.vue'
import CategoryLoads from '@/views/CategoryLoads.vue'
import Error from '@/views/Error.vue'

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/auto-matching',
    name: 'Suggestions',
    component: Suggestions,
  },
  {
    path: '/import',
    name: 'Import',
    component: Import,
  },
  {
    path: '/matching-categories',
    name: 'Matching-Categories',
    component: MatchingCategories,
  },
  {
    path: '/users-administration',
    name: 'Users-Administration',
    component: UsersAdministration,
  },
  {
    path: '/matches',
    name: 'Matches',
    component: Matches,
  },
  {
    path: '/user-profile',
    name: 'User-Profile',
    component: UserProfile,
  },
    {
    path: '/category-loads',
    name: 'Category-Loads',
    component: CategoryLoads,
  },
  {
    path: "/:pathMatch(.*)*",
    name: 'Error',
    component: Error,
  },
]

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const onlyWhenLoggedOut = to.matched.some(record => record.meta.layout === 'default')
  const loggedIn = store.state.authModule.authtoken
  if (!loggedIn && to.path !== '/login') {
    next('/login')
  } else if (onlyWhenLoggedOut && loggedIn) {
    next('/suggestions')
  } else {
    next()
  }

})

export default router
