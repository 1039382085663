import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

const app = createApp(App)

app
  .use(store)
  .use(router)
  .use(vuetify);

app.mount('#app')

// what about this??
// Vue.config.productionTip = false

