import router from '../../router'
import axios from 'axios'
import { AppUrl, guest_api as guest } from '@/assets/js/variables'
import VueCookies from 'vue-cookies'
import JwtService from '@/assets/js/JwtService'
import { empty_object } from '@/assets/js/myFunctions'

const state = {
  authtoken: VueCookies.get('authtoken'),
  user: {},
  client: {},
  isLoading: false,
  token_check: undefined,
  elasticities: []
}

const getters = {
  isLoggedIn(state) {
    return !!state.authtoken;
  }
}

const actions = {
  getUser({ commit, dispatch }, credentials) {
    // axios.defaults.withCredentials = true
    const params = new URLSearchParams();
    params.append('username', credentials.username)
    params.append('password', credentials.password)
    params.append('grant_type', 'password')
    params.append('task', 'login')
    return new Promise((resolve, reject) => {
      guest.post(AppUrl, params)
        .then(response => {
          if (response.data.payload.loggedIn) {
            const token = response.data.Authorization.access_token
            commit('SET_userLogin', token)
            VueCookies.set('authtoken', token, '1d')
            commit('SET_AUTHTOKEN', token)
            JwtService.saveToken(token, response.data.Authorization.expires_in)
            dispatch('getClientData');
            resolve(1)
          } else {
            resolve(0)
          }
        })
        .catch(error => {
          console.log(error)
          resolve(0);
        })
    })
  },
  async getUserData({ commit, getters }) {
    const params = { headers: { Authorization: `Bearer ${JwtService.getToken()}` } };
    if (getters.isLoggedIn) {
      try {
        const { data } = await axios.post(AppUrl, { task: 'getUserInfo' }, params)
        commit('SET_USER', data.user)
      } catch (error) {
        console.log(error)
        commit('logout')
      }
    }
  },
  async getClientData({ commit, getters }) {
    const params = { headers: { Authorization: `Bearer ${JwtService.getToken()}` } };
    if (getters.isLoggedIn) {
      try {
        const { data } = await axios.post(AppUrl, { task: 'getClientInfo' }, params);
        commit('SET_CLIENT', data.client);
        commit('SET_ELASTICITIES', data.client.client_name);
      } catch (error) {
        console.log(error)
        commit('logout')
      }
    }
  },
  signOut({ commit }) {
    commit('logout')
  }
}

const mutations = {
  SET_userLogin(state, token) {
    state.authtoken = token
  },
  SET_USER(state, user) {
    state.user = user
  },
  SET_CLIENT(state, client) {
    state.client = client
  },
  SET_ELASTICITIES(state, client) {
    state.elasticities = [
      { title: `${client} Better Specs`, key: 1 },
      { title: "Identical or Same", key: 2 },
      { title: `${client} Lower Specs`, key: 3 },
    ];
  },
  SET_loading(state, bool) {
    state.isLoading = bool
  },
  SET_AUTHTOKEN(state, id) {
    state.authtoken = id
  },
  logout(state) {
    state.authtoken = null
    VueCookies.remove('authtoken');
    JwtService.destroyToken();
    state.user = empty_object(state.user)
    state.client = empty_object(state.client)
    router.currentRoute.name != 'Login' && router.replace('/login')
  },
  SET_CHECK(state) {
    const that = this;
    state.token_check = setInterval(function () {
      that.commit('authModule/check_expiration')
    }(), 300000);
  },
  check_expiration() {
    const is_active = JwtService.isActiveToken();
    if (!is_active) {
      this.commit('authModule/logout')
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
