<template>
  <v-container d-flex class="pt-0">
        <v-select
          v-model="competitor"
          :items="competitors"
          label="Competitor"
          item-title="domain_name"
          item-value="domain_id"
          @change="get_competitor_categories"
        ></v-select>
  </v-container>
</template>

<script>
export default {
  name: "competitor-component",
  methods: {
    // this is used in categories matching
    get_competitor_categories(domain_id) {
      this.$store.dispatch('competitorsModule/GET_CATEGORIES', domain_id)
      this.$emit('change_competitor');
    },
  },
  computed: {
    competitors: function () {
      return this.$store.state.competitorsModule.competitors;
    },
    selected_competitor() {
      return this.$store.state.competitorsModule.selected_competitor;
    },
    competitor: {
      get () {
        let competitorID = this.$store.state.competitorsModule.selected_competitor.domain_id;
        return (competitorID > 0) ? competitorID : null;
      },
      set (value) {
        this.$store.commit('competitorsModule/updateCompetitor', value);
      }
    }
  },
  watch: {
    competitor: function() {
      // if we had suggestions loaded, clear data on change
      if (this.$store.state.competitorsModule.competitor_table.products.length > 0) {
        this.$store.commit('competitorsModule/CLEAR_DATA');
        this.$store.commit('clientModule/CLEAR_DATA');
      }
    },
  },
  created() {
      this.$store.dispatch("competitorsModule/LoadCompetitors");
  }
};
</script>
