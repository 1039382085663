/* eslint-disable */
import axios from "axios";
import { AppUrl } from "@/helpers/variables";
import JwtService from "@/assets/js/JwtService";
import { empty_object } from "@/assets/js/myFunctions";

const state = {
  hasItem: false,
  skus: [],
  selected_item: { // review properties
    id: 0,
    brand: "",
    brand_title: "",
    image: "",
    price: 0,
    sku: 0,
    title: "",
    specs: {},
  },
  selected_item_id: '',
  categories: [],
  loader: false,
  suggestions_upload_sucess: false,
  show_review_matches_table: false,
  similar_data: [],
  overview_loading: false,
  overview_loaded: false
};

const getters = {};

const mutations = {
  SET_SKUS(context, payload) {
    context.skus = payload;
  },
  EMPTY_CLIENT_SELECTED_ITEM(state) {
    state.selected_item_id = '';
    state.selected_item = empty_object(state.selected_item);
    state.hasItem = false;
  },
  SET_CLIENT_SELECTED_ITEM(state, payload) {
    state.selected_item_id = payload.id;
    state.selected_item = payload;
    state.hasItem = true;
  },
  upd_id_url_funcfor_delete(state, payload) {
    state.selected_item.id = payload.data_id;
    state.selected_item.url = payload.url;
  },
  SET_CATEGORIES(state, payload) {
    state.categories = payload;
  },
  EMPTY_MODULE(state) {
    state.hasItem = false
    state.skus = []
    state.selected_item = empty_object(state.selected_item)
    state.selected_item_id = ''
    state.categories = []
    state.loader = false
    state.suggestions_upload_sucess = false;
    state.show_review_matches_table = false;
    state.similar_data = [];
    state.overview_loading = false;
    state.overview_loaded = false;
  },
  SET_SUGGESTIONS_UPLOAD_SUCCESS(state, payload) {
    state.suggestions_upload_sucess = payload;
  },
  SET_SHOW_REVIEW_MATCHES_TABLE(state, value) {
    state.show_review_matches_table = value;
  },
  SET_SELECTED_SUGGESTIONS(state, value) {
    state.selected_suggestions = value;
  },
  REMOVE_MATCHING_ITEM(state, item_id) { // splice item with same id
    state.selected_suggestions = state.selected_suggestions.filter(el => el.data_id !== item_id);
  },
  SET_SIMILAR_DATA(state, value) {
    state.similar_data = value;
  },
  SET_OVERVIEW_LOADING(state, value) {
    state.overview_loading = value;
  },
  SET_OVERVIEW_LOADED(state, value) {
    state.overview_loaded = value;
  },
  CLEAR_DATA(state) {
    state.selected_suggestions = [];
    state.show_review_matches_table = false;
  }
};

const actions = {
  clientSkus(context, payload) {
    const params = { headers: { Authorization: `Bearer ${JwtService.getToken()}` } }
    let send = {
      task: 'clientSkuSuggest',
      payload: {
        text: payload,
      }
    }
    // why is this a post and not a get?
    return new Promise((resolve, reject) => {
      axios
        .post(AppUrl, send, params)
        .then(function (response) {
          context.commit('SET_SKUS', response.data.payload);
          resolve(response);
        })
        .catch((err) => {
          console.log(err);
          context.commit('authModule/logout', null, { root: true });
          reject(err)
        });
    });
  },

  getSimilarData(context) {
    context.commit('SET_OVERVIEW_LOADING', true);
    const params = { headers: { Authorization: `Bearer ${JwtService.getToken()}` } }
    const payload = {
      task: "getSimilarMatches",
      payload: {
        client_id: context.rootState.authModule.client.client_id,
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .post(AppUrl, payload, params)
        .then(function (res) {
          if (res.data.payload.similars != null) {
            context.commit('SET_SIMILAR_DATA', res.data.payload.similars)
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err)
        })
        .finally(() => {
          context.commit('SET_OVERVIEW_LOADING', false);
          context.commit('SET_OVERVIEW_LOADED', true);
        });
    });
  },

  selectSku(context, payload) {
    context.commit('EMPTY_CLIENT_SELECTED_ITEM');
    if (!!payload && payload.length > 2) {
      context.state.loader = true;
      let send = {
        task: 'clientProductSelect',
        payload: {
          data_id: payload,
        }
      };
      return new Promise((resolve, reject) => {
        const params = { headers: { Authorization: `Bearer ${JwtService.getToken()}` } }
        axios
          .post(AppUrl, send, params)
          .then(function (response) {
            const data = response.data.payload
            if (data.products.length) context.commit('SET_CLIENT_SELECTED_ITEM', data.products[0]);
            context.commit('upd_id_url_funcfor_delete', payload);
            resolve(response);
          })
          .catch((err) => {
            console.log(err)
            context.commit('authModule/logout', null, { root: true });
            reject(err)
          }).finally(() => { context.state.loader = false });
      });
    }

  },
  GET_CATEGORIES(context) {
    if (state.categories.length) return
    const payload = {
      task: 'get_clients_categories',
      payload: {
        client_id: context.rootState.authModule.client.client_id,
      }
    }
    const params = { headers: { Authorization: `Bearer ${JwtService.getToken()}` } }
    return new Promise((resolve) => {
      axios
        .post(AppUrl, payload, params)
        .then(function (response) {
          (response.data.categories) && (context.commit('SET_CATEGORIES', response.data.categories));
          resolve(response);
        })
        .catch((err) => {
          console.log('error')
          context.commit('authModule/logout', null, { root: true });
          reject(err)
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
