<template>
  <v-container d-flex flex-row class="pa-5">
    <v-row>
      <v-col cols="12" lg="3" xl="3">
        <v-card title="Provide SKU">
          <SelectedProduct />
        </v-card>
        <v-card title="Select a competitor" class="mt-5" >
          <Competitor />
        </v-card>
        <v-container v-show="!show_competitor_products_table && !show_review_matches_table" class="pa-0 mt-5">
          <ProductsFilter :disable_button="disable_get_products_button" />
        </v-container>
      </v-col>
      <v-col cols="12" lg="9" xl="9"  class="pl-2">
        <v-card v-show="loading_products">
          <v-skeleton-loader type="table-thead,table-tbody">
          </v-skeleton-loader>
        </v-card>
        <v-container v-show="show_table_container" class="pa-0">
          <CompProductsPreview />
        </v-container>
        <v-container v-show="show_review_matches_table" class="pa-0">
          <SuggestedPreview />
        </v-container>
      </v-col>
    </v-row>

    <!-- success dialog -->
    <v-dialog v-model="show_success_dialog">
      <v-card>
        <v-card-text class="mb-5">
          All matches were submited successfully!
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="afterSuccess">ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- no products dialog -->
    <v-dialog v-model="show_no_products_dialog">
      <v-card>
        <v-card-text class="mb-5">
          No matching suggestions found. Please try a different product or a different competitor.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="show_no_products_dialog = false">ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import SelectedProduct from "@/components/SelectedProduct.vue";
import ProductsFilter from "@/components/ProductsFilter.vue";
import CompProductsPreview from "@/components/CompProductsPreview.vue";
import Competitor from "@/components/Competitor.vue";
import SuggestedPreview from "@/components/SuggestedPreview.vue";

export default {
  name: "compare-view",
  components: {
    SelectedProduct,
    ProductsFilter,
    CompProductsPreview,
    Competitor,
    SuggestedPreview,
  },
  data: () => ({

  }),
  methods: {
    afterSuccess() {
      // close dialog
      this.$store.commit('clientModule/SET_SUGGESTIONS_UPLOAD_SUCCESS', false);
      // clear data
      this.$store.commit('clientModule/CLEAR_DATA');
      this.$store.commit('competitorsModule/SET_PRODUCTS', []);
    }
  },
  computed: {
    show_review_matches_table: function() {
      return this.$store.state.clientModule.show_review_matches_table;
    },
    show_competitor_products_table: function() {
      return this.$store.state.competitorsModule.competitor_table.products.length > 0 && !this.show_review_matches_table;
    },
    disable_get_products_button: function() {
      let dis = true;
      if (this.$store.state.competitorsModule.selected_competitor.domain_id !== "" && this.$store.state.clientModule.hasItem && !this.loading_products && !this.show_review_matches_table) {
        dis = false;
      }
      return dis;
    },
    loading_products: function() {
      return this.$store.state.competitorsModule.loading;
    },
    show_success_dialog: {
      get () {
        return this.$store.state.clientModule.suggestions_upload_sucess;
      },
      set (value) {
        this.$store.commit('clientModule/SET_SUGGESTIONS_UPLOAD_SUCCESS', value);
      }
    },
    show_no_products_dialog: {
      get () {
        return this.$store.state.competitorsModule.show_no_products_dialog;
      },
      set (value) {
        this.$store.commit('competitorsModule/SET_SHOW_NO_PRODUCTS_DIALOG', value);
      }
    },
    show_table_container: function() {
      return this.loading_products || this.show_competitor_products_table;
    }
  },
};
</script>

<style>


</style>
