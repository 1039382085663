<template>
  <v-card>
    <v-data-table :headers="headers" :items="suggestions" items-per-page="-1">
      <template v-slot:top>
        <v-toolbar title="Review matches"></v-toolbar>
      </template>
      <template v-slot:item="{ item }">
        <v-hover>
          <template v-slot:default="{ isHovering, props }">
            <tr v-bind="props" :class="{ 'row-hovered': isHovering }">
              <td class="v-data-table-column--align-center">
                <v-img v-if="item.image == null" height="3.5em" :src="require('@/assets/images/default_product.jpg')"/>
                <v-img v-else height="3.5em" class="ma-1" :src="item.image"/>
              </td>
              <td>{{client_sku}}</td>
              <td>{{item.sku}}</td>
              <td>
                <a v-bind="props" :href="item.url" target="_blank" rel="noopener noreferrer">{{ item.url }}</a>
              </td>
              <td>{{item.title}}</td>
              <td>{{ formatNumber(item.price) }} €</td>
              <td><v-select v-model="item.elasticity" :items="elasticities"></v-select></td>
              <td class="v-data-table-column--align-end">
                <v-tooltip>
                  <template v-slot:activator="{ props }" >
                      <v-icon v-show="item.isUploaded == false" v-bind="props" size="small" color="warning">mdi-information</v-icon>
                  </template>
                  <span>{{item.message}}</span>
                </v-tooltip>
                <v-icon size="small" @click="deleteItem(item.data_id)" color="error" class="ml-2">mdi-delete</v-icon>
              </td>
            </tr>
          </template>
        </v-hover>
      </template>
      <!-- to get rid of all the pagination controls -->
      <template #bottom>
        <div class="pb-5"> </div>
      </template>
    </v-data-table>
  </v-card>
  <div class="d-flex btn-floating mt-4">
    <v-spacer></v-spacer>
    <v-btn size="x-large" prepend-icon="mdi-chevron-left" :disabled="loading_upload" @click="closeDialog" rounded="lg" class="button-full-elevated " >back</v-btn>
    <v-btn size="x-large" prepend-icon="mdi-cloud-upload" @click="upload" :loading="loading_upload" rounded="lg" class="button-full-elevated ml-5">save matches</v-btn>
  </div>

  <v-dialog v-model="show_error_dialog">
    <v-card title="Error on save">
        <v-card-text>
        Matches that were succesfully saved have been removed from the table.
        </v-card-text>
        <v-card-text>
        The remaining matches run into an error. Hover over the information icon
        <v-icon size="small" color="warning">mdi-information</v-icon>
        to view the particular issue with each item.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="show_error_dialog = false">ok</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import {AppUrl} from "@/helpers/variables";
import JwtService from '@/assets/js/JwtService';
import { formatNumber } from "@/helpers/commonFunctions.js"; // these also need to go to the methods

  export default {
    data: () => ({
      headers: [
        { title: 'Image',  key: 'image', align: 'center', sortable: false, },
        { title: 'SKU', key: 'sku', sortable: false },
        { title: "Competitor SKU", key: 'comp_sku', sortable: false },
        { title: "URL",  key: 'title',  sortable: false },
        { title: "Title", sortable: false },
        { title: "Price", key: 'price', width: '80', sortable: false },
        { title: "Matching elasticity", key: 'matching_elasticity', width: '240',  sortable: false },
        { title: "Actions", key: 'actions', width: '90',  sortable: false },
      ],
      loading_upload: false,
      show_error_dialog: false,
    }),

    computed: {
      suggestions () {
        return this.$store.state.clientModule.selected_suggestions;
      },
      client_sku: function() {
        return this.$store.state.clientModule.selected_item.sku
      },
      elasticities: function() {
        return this.$store.state.authModule.elasticities;
      },
    },

    methods: {
      deleteItem (item_id) {
        this.$store.commit('clientModule/REMOVE_MATCHING_ITEM', item_id);
      },
      params () {
      return { headers: { Authorization: `Bearer ${JwtService.getToken()}` } }
    },
    closeDialog () {
      this.$store.commit('clientModule/SET_SHOW_REVIEW_MATCHES_TABLE', false);
    },
    upload() {
      this.loading_upload = true;
      let matches =[];
      for (let item of this.suggestions) {
        let elast = this.elasticities.find((e) => item.elasticity.toLowerCase() == e.title.toLowerCase()).key;
        matches.push({client_sku: this.client_sku, competitor_sku: item.sku, competitor_url: item.url, matching_elasticity: elast });
      }
      const payload = {
        task: 'importMatchingProducts',
        payload: {
          csvData: matches
        }
      }
      axios.post(AppUrl, payload, this.params()).then(res => {
        if (res.data.payload.error_keys.length > 0) { // error
          this.show_error_dialog = true;
          const success_keys = res.data.payload.success_keys;
          const error_keys = res.data.payload.error_keys;
          const messages = res.data.payload.messages;

          // set flags and messages
          this.suggestions.forEach((row, index) => {
            if(error_keys.includes(index)){
              Object.assign(row, {isUploaded: false})
            } else if(success_keys.includes(index)) {
              Object.assign(row, {isUploaded: true})
            }
            Object.assign(row, {message: messages[index]})
          }, this);
          // remove items that were uploaded
          for (let item of this.suggestions) {
            if (item.isUploaded) {
              this.deleteItem(item.data_id);
            }
          }

        } else { //success
          this.$store.commit('clientModule/SET_SUGGESTIONS_UPLOAD_SUCCESS', true);
        }
        // update overview table
        this.$store.dispatch('clientModule/getSimilarData');

      }).catch( err => {
        console.log('Error', err);
      }).finally(() => {this.loading_upload = false})
    },
    formatNumber
  },
}
</script>