<template>
  <v-app>
    <v-app-bar order="1">
      <div class="d-flex flex-row justify-center mx-5 nav-logo" >
        <span class=" text-primary logo-text">inf</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="text-primary bg-secondary"
          width="40"
          height="46"
          fill="white"
          viewBox="0 -4 24 30">
          <path
            d="M18.572 6c-6.018 0-8.414 10-13.143 10-2.114 0-3.479-1.578-3.479-4s1.366-4 3.479-4c1.666 0 2.862 1.069 4.017 2.395l1.244-1.561c-1.499-1.533-3.05-2.834-5.261-2.834-3.197 0-5.429 2.455-5.429 6s2.232 6 5.429 6c6.003 0 8.407-10 13.143-10 2.113 0 3.479 1.578 3.479 4s-1.365 4-3.479 4c-1.664 0-2.86-1.068-4.015-2.392l-1.244 1.561c1.499 1.531 3.05 2.831 5.259 2.831 3.197 0 5.428-2.455 5.428-6s-2.231-6-5.428-6z"
          >
          </path>
        </svg>
        <span class=" text-primary logo-text">rder</span>
        <v-icon size="large" class="logo-dot">mdi-circle-small</v-icon>
        <span class=" font-weight-regular">similar </span>
      </div>
      <v-spacer></v-spacer>
      <v-sheet v-show="isLoggedIn" class="mx-5">
        <span>Hi, {{ user.firstname }}</span>
        <v-btn class="ms-5 bg-primary" @click="signOut" text="sign out" size="small"></v-btn>
      </v-sheet>
    </v-app-bar>

     <v-navigation-drawer v-if="isLoggedIn" rail expand-on-hover order="2" permanent>
        <v-list density="comfortable" nav color="primary">
          <v-list-item
            v-for="item in items.filter(i => i.allow.includes(user.is_admin))"
            :key="item.title"
            link
            :to="{ name: item.to }"
            :prepend-icon="item.icon"
            :title="item.title"
          >
          </v-list-item>
        </v-list>
     </v-navigation-drawer>
    <v-main>
      <div class="back-logo" style="">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="text-primary"
          width="700"
          fill="hsla(175, 80%, 1%, 20%)"
          viewBox="0 0 151.3 73.5">
            <path
              d="M116,70.3c-9.1,0-16.9-3.1-23.3-9.6c-3.6-3.6-7.3-7.2-10.9-10.8c-0.3-0.3-0.6-0.6-0.9-1
                c-1.5-1.8-1.5-4.2,0.1-5.8c1.6-1.5,4.1-1.5,5.8,0.1c2,1.9,3.9,3.9,5.8,5.8c2.3,2.2,4.4,4.6,6.8,6.7c7.1,6.3,15.3,8.2,24.2,4.9
                c8.9-3.2,14.3-9.8,15.7-19.2c2.2-14.5-9.4-27.6-24.1-27.3c-6.9,0.1-12.5,3-17.3,7.8C85.2,34.7,72.4,47.4,59.7,60.2
                c-5.4,5.5-11.8,8.9-19.4,9.9C21.1,72.4,5.8,58.4,4.2,41.7c-1-10.4,1.9-19.7,9.7-26.9C27.4,2.3,47,3,59.9,16.2
                c3.6,3.7,7.2,7.3,10.8,10.9c1.3,1.3,1.6,2.9,1.1,4.5c-0.5,1.5-1.7,2.3-3.2,2.6c-1.6,0.3-2.8-0.4-3.9-1.5C60.9,28.9,57,25,53.1,21.2
                c-8.8-8.6-22.2-9.5-31.6-1.9c-8.1,6.6-11,15.1-8.3,25.2c2.6,9.9,9.5,15.6,19.5,17.4c8.2,1.5,15.1-1.4,20.8-7.1
                c13.2-13.2,26.4-26.4,39.7-39.6c15.7-15.6,44.7-11.2,53,12.9c6.5,18.9-5.9,38.9-25.6,42.1C119.1,70.3,117.5,70.2,116,70.3z"
                        >
          </path>
        </svg>
      </div>

      <RouterView />
    </v-main>
  </v-app>
</template>

<script>
import { useTheme } from 'vuetify'

  export default {
    components: {},

    data: () => ({
      theme: useTheme(),
      items: [
          { title: 'Auto matching', icon: 'mdi-eye-plus', to: 'Suggestions', allow: [1, 0]},
          { title: 'Manual matching', icon: 'mdi-pencil-plus', to: 'Import', allow: [1, 0]},
          { title: 'Matches overview', icon: 'mdi-database', to: 'Matches', allow: [1, 0]},
          { title: 'User profile', icon: 'mdi-account', to: 'User-Profile', allow: [0]},
          { title: 'User administration', icon: 'mdi-account-group', to: 'Users-Administration', allow: [1]},
          // { title: 'Category loads', icon: 'mdi-scale-unbalanced', to: 'Category-Loads', allow: [1]},
        ],
    }),
    methods: {
      signOut () {
        this.$store.dispatch('authModule/signOut')
      }
    },
    computed: {
      isLoggedIn() {
        return this.$store.getters["authModule/isLoggedIn"];
      },
      user() {
        return this.$store.state.authModule.user;
      },
      client() {
        return this.$store.state.authModule.client;
      }
    },
    watch: {
      isLoggedIn : function (newLoggedIn) {
        (!this.user.user_id) && this.$store.dispatch('authModule/getUserData');
        (!this.client.client_id) && this.$store.dispatch('authModule/getClientData');
      }
    },
    created() {
      this.$store.commit('authModule/SET_CHECK');
      (!this.user.user_id) && this.$store.dispatch('authModule/getUserData');
      (!this.client.client_id) && this.$store.dispatch('authModule/getClientData');
    }
  }
</script>

<style lang="scss">

.nav-logo {
  .logo-dot{
    height:50px;
    width:10px;
  }
  .logo-dot:before,
  .logo-dot {
    width:12px;
    margin-left: -12px;
  }
  span {
    letter-spacing: 0.05em;
    font-size: 1.8em;
  }
  .logo-text {
    font-weight: 600;
  }
  .logo-secondary{
    font-weight: 400;
  }
}
.back-logo {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

</style>