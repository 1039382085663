/*eslint-disable*/
const ID_TOKEN_KEY = "id_token";
const ID_TOKEN_EXP = "token_expiration";
const ID_ROLE = "agent_role";

/**
 * @description get token form localStorage
 */
export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token, exp_sec) => {
  console.log('window 1', JSON.parse(JSON.stringify(window.localStorage)))
  window.localStorage.setItem(ID_TOKEN_KEY, token);
  const d = new Date();
  let exp_time = d.getTime();
  const exp_ms = exp_sec * 1000;
  exp_time += exp_ms;
  window.localStorage.setItem(ID_TOKEN_EXP, `${exp_time}`);
  console.log('window 2', JSON.parse(JSON.stringify(window.localStorage)))
};

const saveRole = (isAdmin) => {
  const role = isAdmin ? "1" : "0";
  console.log("role format");
  console.log(role);
  console.log(isAdmin);
  window.localStorage.setItem(ID_ROLE, role);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = ()=> {
  console.log('token destroy')
  window.localStorage.removeItem(ID_TOKEN_KEY);
  window.localStorage.removeItem(ID_TOKEN_EXP);
};

export const isActiveToken = () => {
  const d = new Date();
  const time = d.getTime();
  const expires = getTokenExpireTime();
  if (time < expires) {
    return true;
  } else {
    return false;
  }
};

const getTokenExpireTime = () => {
  const expires = window.localStorage.getItem(ID_TOKEN_EXP);
  const num = expires ? parseInt(expires) : 0;
  return num;
};

export default { getToken, saveToken, saveRole, destroyToken, isActiveToken };
