<template>
  <v-card v-show="show_competitor_products_table">
    <v-data-table
      :headers="headers"
      :items="products"
      :sort-by="[{key: sortBy, order: sortDesc}]"
      :search="selectFor"
      show-select
      select-strategy="page"
    >
      <template v-slot:top>
        <v-toolbar title="Proposed matches">
          <v-spacer></v-spacer>
          <v-text-field
            label="Search"
            v-model="selectFor"
            prepend-inner-icon="mdi-magnify"
            style="width: 50px"
            class="pa-3"
          />
        </v-toolbar>
      </template>

      <template v-slot:item="{ item }">
        <v-hover>
          <template v-slot:default="{ isHovering, props }">
            <tr v-bind="props" :class="{ 'row-hovered': isHovering, 'row-selected': item.selected }">
              <td class="v-data-table-column--no-padding">
                <v-checkbox v-model="item.selected"></v-checkbox>
              </td>
              <td>
                <v-img v-if="item.image == null" max-height="3em" :src="require('@/assets/images/default_product.jpg')"/>
                <v-img v-else height="3.5em" class="ma-1" :src="item.image" @click="openImage(item.title, item.image)"/>
              </td>
              <td>{{ formatNumber(item.titleScore1) }}</td>
              <td>{{item.sku}}</td>
              <td class="overflowing-text--single">
                <v-tooltip>
                  <template v-slot:activator="{ props }" >
                      <a v-bind="props" :href="item.url" target="_blank" rel="noopener noreferrer">{{ item.title }}</a>
                  </template>
                  <span>{{item.title}}</span>
                </v-tooltip>
              </td>
              <td class="overflowing-text--single">
                <v-tooltip>
                  <template v-slot:activator="{ props }" >
                    <span v-bind="props"> {{item.category}} </span>
                  </template>
                  <span>{{item.category}}</span>
                </v-tooltip>
              </td>
              <td>{{ formatNumber(item.price) }} €<span class="ml-2 font-weight-bold delta-text" :class="client_price < item.price ? 'text-success' : 'text-error'"> {{ getDelta(item.price)}}</span></td>
              <td><v-select v-model="item.elasticity" :items="elasticities"></v-select></td>
            </tr>
          </template>
        </v-hover>
      </template>
    </v-data-table>
  </v-card>
  <div class="d-flex mt-4">
    <v-spacer></v-spacer>
  <v-btn append-icon="mdi-chevron-right" :disabled="disable_overview" size="x-large" @click="reviewMatches" rounded="lg" class="button-full-elevated">
    Review matches </v-btn>
  </div>
  <v-dialog v-model="image_dialog.show">
    <v-card :title="image_dialog.title">
      <v-card-text>
        <v-img :src="image_dialog.body" />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="image_dialog.show = false">close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { formatNumber } from "@/helpers/commonFunctions.js"; // these also need to go to the methods
  export default {
    data () {
      return {
        image_dialog: {
          show: false,
          title: '',
          body: '',
        },
        selectFor: "",
        sortBy: 'titleScore1',
        sortDesc: 'desc',
        headers: [
          { title: 'Image', sortable: false, key: 'image' },
          { title: 'Score', key: 'titleScore1' },
          { title: 'SKU', key: 'sku' },
          { title: 'Title', key: 'title' },
          { title: 'Categories', key: 'category' },
          { title: 'Price', key: 'price', width:'120' },
          { title: 'Matching elasticity', key: 'matching_elasticity', width: '250' },
        ],
      }
    },
    computed: {
      products: function() {
        let products = [];
        if (this.$store.state.competitorsModule.loading) {
          products = [];
        }  else {
          products = this.$store.state.competitorsModule.competitor_table.products.map((item) => {
            item.elasticity = "Identical or Same";
            item.selected = false;
            return item;
          });
        }
        return products;
      },
      elasticities: function() {
        return this.$store.state.authModule.elasticities;
      },
      disable_overview: function() {
        return !this.products.some(el => el.selected == true);
      },
      client_price: function() {
        return this.$store.state.clientModule.selected_item.price;
      },
      show_competitor_products_table: function() {
        return this.$store.state.competitorsModule.competitor_table.products.length > 0 && !this.show_review_matches_table;
      },
    },
    methods: {
      openImage(title, img_url) {
        this.image_dialog.show = true;
        this.image_dialog.title = title;
        this.image_dialog.body = img_url;
      },
      reviewMatches() {
        let matches = [];
        for (let pr of this.products) {
          if (pr.selected) {
            let match = pr;
            match.isUploaded = null;
            matches.push(match);
          }
        }
        this.$store.commit('clientModule/SET_SELECTED_SUGGESTIONS', matches);
        this.$store.commit('clientModule/SET_SHOW_REVIEW_MATCHES_TABLE', true);
      },
      getDelta(price) {
        let delta =  price - parseFloat(this.client_price);
        if (delta == 0) {
          return "";
        }
        delta = this.formatNumber(delta);
        let text = (delta > 0) ? "+" + delta.toString() : delta.toString();
        return text;
      },
      formatNumber,
    },
  }
</script>

<style lang="scss">
.delta-text {
  font-size: 13px;
  vertical-align: top;
  font-weight: 600;
  margin-top: -5px;
  display: inline-flex;
}
</style>