<template>
  <v-container class="pa-5">
    <v-card>
      <v-toolbar>
        <v-tabs v-model="tabsModel">
          <v-tab style="font-weight:700; font-size:1em;" value="mass">mass import </v-tab>
          <v-tab style="font-weight:700; font-size:1em;" value="single">single import </v-tab>
        </v-tabs>
      </v-toolbar>
      <v-spacer> </v-spacer>
      <v-window v-model="tabsModel">
      <!-- mass import tab -->
        <v-window-item value="mass">
          <v-card title="Select one or more spreadsheet files to import multiple matches">
            <v-card-text class="d-flex">
              <v-file-input v-model="file" label="Upload file" style="max-width: 500px" accept=".csv, .xml, .xlsx " @change="fileSelect"></v-file-input>
              <v-tooltip>
                <template v-slot:activator="{ props }">
                  <v-btn icon v-bind="props" @click="download_template" color="secondary">
                    <v-icon size="large" color="primary">mdi-file-excel</v-icon>
                  </v-btn>
                </template>
                <span>Download an excel template</span>
              </v-tooltip>
            </v-card-text>
          </v-card>
        </v-window-item>
        <!-- single import tab -->
        <v-window-item value="single">
          <v-card title="Complete the fields below to add a new match">
            <v-row class="pa-5">
              <v-col>
                <v-text-field v-model="form.sku" hide-details :label=client_name_label></v-text-field>
              </v-col>
              <v-col>
                <v-text-field v-model="form.competitor_sku" hide-details label="Competitor's SKU"></v-text-field>
              </v-col>
              <v-col sm="12" md="5">
                <v-text-field v-model="form.url" hide-details label="Competitor's product URL"></v-text-field>
              </v-col>
              <v-col>
                <v-select v-model="form.elasticity" :items="elasticities" label="Matching Elasticity"></v-select>
              </v-col>
              <v-col>
                <v-btn size="x-large" prepend-icon="mdi-plus" @click="add_manual_item" :disabled="this.form.sku === '' || this.form.url === '' || !this.form.elasticity ">
                    Add Item
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-window-item>
      </v-window>
    </v-card>

    <!-- table with imports, shared for single and mass imports -->
    <v-card v-show="csvData.length" class="mt-5">
      <v-data-table
        :headers="headers"
        :items="csvData"
        :search="search"
        :loading="loading_table"
      >
        <template v-slot:top>
          <v-toolbar title="Matches preview" class="pr-2">
            <v-spacer></v-spacer>
            <v-text-field
              label="Search"
              v-model="search"
              class="pa-3"
              prepend-inner-icon="mdi-magnify"
              style="width: 50px"
            />
            <v-btn class="mx-1 bg-error" @click="dialogDeleteMass = true" v-if="csvData.filter((d) => !d.isManual).length > 0" prepend-icon="mdi-delete">
                Clear all
                <span class="font-weight-black mx-1 text-decoration-underline">mass</span>
                imports
            </v-btn>
            <v-btn class="mx-1 bg-error" @click="dialogDeleteSingle = true" v-if="csvData.filter((d) => d.isManual).length > 0" prepend-icon="mdi-delete">
                Clear all <span class="font-weight-black mx-1 text-decoration-underline">single</span>imports
              </v-btn>
          </v-toolbar>
        </template>
        <!-- control how each row is shown -->
        <template v-slot:item="{ item }">
          <v-hover>
            <template v-slot:default="{ isHovering, props }">
              <tr v-bind="props" :class="{'row-hovered': isHovering}">
                <td>{{item.client_sku}}</td>
                <td>{{item.competitor_sku}}</td>
                <td class="overflowing-text--single">
                  <v-tooltip>
                    <template v-slot:activator="{ props }" >
                        <a v-bind="props" :href="item.competitor_url" target="_blank" rel="noopener noreferrer">{{ item.competitor_url }}</a>
                    </template>
                    <span>{{item.competitor_url}}</span>
                  </v-tooltip>
                </td>
                <td>{{item.matching_elasticity}}</td>
                <td class="v-data-table-column--align-end">
                  <v-tooltip>
                    <template v-slot:activator="{ props }" >
                        <v-icon v-show="item.isUploaded == false" v-bind="props" size="small" class="mr-2" color="warning">mdi-information</v-icon>
                    </template>
                    <span>{{item.message}}</span>
                  </v-tooltip>
                  <v-icon size="small" class="mr-2" @click="editItem(item)" color="primary"> mdi-pencil</v-icon>
                  <v-icon size="small" @click="deleteItem(item)" color="error">mdi-delete</v-icon>
                </td>
              </tr>
            </template>
          </v-hover>
        </template>
        <template v-slot:no-data>
          <v-btn> Reset </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <div class="d-flex mt-5">
      <v-spacer></v-spacer>
        <v-btn prepend-icon="mdi-cloud-upload" class="button-full-elevated" v-show="csvData.length" size="x-large" rounded="lg" @click="upload" :loading="loading_upload">
          Save matches
        </v-btn>
    </div>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      {{ snackbar.message }}
    </v-snackbar>
  </v-container>

  <!-- are these modals duplicated with admin matches page? should we create components for them? -->
  <!-- edit item pop up -->
  <v-dialog v-model="dialog" max-width="800px">
    <v-card title="Edit Match">
      <v-card-text>
        <v-container>
          <v-row>
              <v-col cols="12" sm="6">
              <v-text-field
                v-model="editedItem.client_sku"
                label="SKU"
              ></v-text-field>
            </v-col>
              <v-col cols="12" sm="6">
              <v-text-field
                v-model="editedItem.competitor_sku"
                label="Competitor's SKU"
              ></v-text-field>
            </v-col>
              <v-col cols="12">
              <v-text-field
                v-model="editedItem.competitor_url"
                label="Competitor's URL"
              ></v-text-field>
            </v-col>
              <v-col cols="12" sm="6">
              <v-select
                v-model="editedItem.matching_elasticity"
                :items="elasticities"
                label="Matching Elasticity"
                item-value="text"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
          <v-btn @click="close">Cancel</v-btn>
          <v-btn @click="save">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- delete item pop up -->
  <v-dialog v-model="dialogDelete">
    <v-card>
      <v-card-text>Are you sure you want to delete this item?</v-card-text>
      <v-card-actions class="pt-10">
        <v-spacer></v-spacer>
          <v-btn @click="closeDelete">Cancel</v-btn>
          <v-btn @click="deleteItemConfirm" color="error" class="bg-error">Delete</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- delete mass imports pop up -->
  <v-dialog v-model="dialogDeleteMass">
    <v-card>
      <v-card-text> Are you sure you want to delete all mass imports?</v-card-text>
      <v-card-actions class="pt-10">
        <v-spacer></v-spacer>
          <v-btn @click="dialogDeleteMass = false">Cancel</v-btn>
          <v-btn @click="deleteMassConfirm" color="error" class="bg-error">Delete</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- delete single imports pop up -->
  <v-dialog v-model="dialogDeleteSingle">
    <v-card>
      <v-card-text>Are you sure you want to delete all single imports?</v-card-text>
      <v-card-actions class="pt-10">
        <v-spacer></v-spacer>
          <v-btn @click="dialogDeleteSingle = false">Cancel</v-btn>
          <v-btn @click="deleteSingleConfirm" color="error" class="bg-error">Delete</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- error on upload pop up -->
  <v-dialog v-model="dialogError" max-width="800px">
    <v-card title="Error on save">
      <v-card-text>
        Matches that were succesfully saved have been removed from the table.
      </v-card-text>
      <v-card-text>
        The remaining matches run into an error. Hover over the information icon
        <v-icon size="small" color="warning">mdi-information</v-icon>
        to view the particular issue with each item.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="dialogError = false">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- success dialog -->
  <v-dialog v-model="show_success_dialog">
    <v-card>
      <v-card-text class="mb-5">
        All matches were submited successfully!
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="show_success_dialog = false">ok</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import papa from 'papaparse';
import {AppUrl, domain} from "@/helpers/variables";
import axios from 'axios';
import ExcelJS from 'exceljs';
import JwtService from '@/assets/js/JwtService';

export default {
  name: "Import-page",
  data() {
    return {
      file: [],
      loading_upload: false,
      loading_table: false,
      csvFields: [],
      csvData: [],
      search: '',
      form: {sku: '', competitor: undefined, competitor_sku: '', url: '', elasticity: undefined },
      dialog: false,
      dialogDelete: false,
      dialogDeleteMass: false,
      dialogDeleteSingle: false,
      dialogError: false,
      editedIndex: -1,
      editedItem: {
        client_sku: '',
        competitor_sku: '',
        competitor_url: '',
        matching_elasticity: '',
      },
      snackbar: {
        show: false,
        message: true,
        color: null,
      },
      tabsModel: "mass",
      show_success_dialog: false
    };
  },
  computed: {
    client_name () {
      return this.$store.state.authModule.client.client_name;
    },
    headers () { return [
        {title: this.client_name + ' SKU', key: "client_sku", sortable: false },
        {title: "Competitor SKU", key: "competitor_sku", sortable: false},
        {title: "Competitor URL", key: "competitor_url", sortable: false},
        {title: "Matching elasticity", key: "matching_elasticity", sortable: false, class: "text-no-wrap"},
        {title: "Actions",key: "actions", sortable: false, align: "end"},
      ];
    },
    allowed_fields () { return [ this.client_name, "Competitor", "Sku Competitor", "Url Competitor", "Matching Elasticity" ]},
    elasticities: function() {
      return this.$store.state.authModule.elasticities;
    },
    client_name_label: function() {
      return this.client_name + "'s SKU";
    }
  },
  methods: {
    params () {
      return { headers: { Authorization: `Bearer ${JwtService.getToken()}` } }
    },
    upload() {
      this.loading_upload = true;
      let data = [];
      for (let row of this.csvData) {
        // check that elasticity is valid
        let elas = 0;
        if (this.elasticities.some(el => el.title === row.matching_elasticity)) {
          elas = this.elasticities.find((e) => row.matching_elasticity.toLowerCase() == e.title.toLowerCase()).key;
        }
        data.push({client_sku: row.client_sku, competitor_sku: row.competitor_sku, competitor_url: row.competitor_url, matching_elasticity: elas, isManual: false });
      }
      const payload = {
        task: 'importMatchingProducts',
        payload: {
          csvData: data,
        }
      }
      axios.post(AppUrl, payload, this.params()).then(res => {
        if (res.data.payload.error_keys.length > 0) { // error
          this.dialogError = true;
          const success_keys = res.data.payload.success_keys
          const error_keys = res.data.payload.error_keys
          const messages = res.data.payload.messages
          this.csvData.forEach((row, index) => {
            if(error_keys.includes(index)){
                Object.assign(row, {isUploaded: false})
                this.dialogError = true;
              } else if(success_keys.includes(index)) {
                Object.assign(row, {isUploaded: true})
              } else {
                Object.assign(row, {isUploaded: null})
              }
              Object.assign(row, {message: messages[index]})
            }, this);
            // remove items that were uploaded
            this.csvData = this.csvData.filter(d => !d.isUploaded);
        } else { // success
          // show success dialog
          this.show_success_dialog = true;
          // clear table rows
          this.csvData = [];
        }
        // update overview data
        this.$store.dispatch('clientModule/getSimilarData');
      }).catch( err => {
        console.log('Error', err);
      }).finally(() => {this.loading_upload = false})
    },
    fileSelect(event) {
      if (!this.file) {
        return;
      }
      // using regex to get file type because file[0].type is inconsistent between machines
      let fileType = this.file[0].name.match(/\.[0-9a-z]+$/);
      if(fileType[0] == '.csv'){
        this.get_csv_file();
      } else {
        this.parseExcel();
      }
      return;

    },
    async parseExcel() {
      if (!this.file[0]) {
        return;
      }
      const expectedHeaders = [{title:"Praktiker", value:'praktiker'},{ title:"Competitor SKU", value: "competitor_sku"}, { title:"Url Competitor", value: "competitor_url"}, { title: "Matching Elasticity", value: "matching_elasticity"}];
      const workbook = new ExcelJS.Workbook();
      const data = [];

      // Load the Excel file
      const arrayBuffer = await this.readFile(this.file[0]);
      const excelData = new Uint8Array(arrayBuffer);
      const readWorkbook = await workbook.xlsx.load(excelData);
      // Iterate over each worksheet and read the data
      readWorkbook.eachSheet((worksheet) => {
        worksheet.eachRow((row) => {
          const rowData = [];
          let last_row = 0;
          row.eachCell((cell, cell_idx) => {
            const row_diff = cell_idx - last_row ;
            if(row_diff != 1){
              this.open_snackbar('Check the rows of this excel', 'red')
              for (let index = 1; index < row_diff; index++) {
                rowData.push('');
              }
            }
            let cell_value = (typeof cell.value !== 'object') ? cell.value : cell.value.text
            rowData.push(cell_value);
            last_row = cell_idx
          });
          data.push(rowData);
        });
      });
      // custom data fix
      const rowHeaders = data.shift();
      let data2 = [];
      data.forEach(element => {
        let one = {
          isManual: false,
          isUploaded: null,
        };
        rowHeaders.forEach((header, h_idx) => {
          try {
            const key = expectedHeaders.find(h => h.title === header).value;
            one[key] = element[h_idx];
          } catch { return }
        })
        data2.push(one)
      });
      const mapped_data = data2.map(d => ({
        "client_sku": d["praktiker"],
        "competitor_sku": d["competitor_sku"],
        "competitor_url": d["competitor_url"],
        "matching_elasticity": d["matching_elasticity"],
        isManual: false,
        isUploaded: null,
        message: '',
      }))
      this.csvData.push(...mapped_data);
    },
    async readFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    get_csv_file() {
      let that = this;

      if(this.file[0]){
        this.loading_table = true;
        papa.parse(this.file[0], {
          header: true,
          dynamicTyping: true,
          skipEmptyLines: true,
          preview: 0,
          complete(results) {
            setTimeout(that.after_csv_actions, 500, results)
          }
        })
      }
    },
    after_csv_actions(results = null) {
      this.csvFields = results.meta.fields.filter(f => this.allowed_fields.includes(f));
      this.csvData = this.csvData.concat(results.data
      .filter(data => Object.values(data).every(v => !!v))// eliminate the null rows
      .map((data) => ({
        "client_sku": data[this.client_name],
        "competitor_sku": data["Competitor SKU"],
        "competitor_url": data["Url Competitor"],
        "matching_elasticity": data["Matching Elasticity"],
        isManual: false,
        isUploaded: null,
      })));

      this.loading_table = false;
    },
    empty_rows( isManual ) {
      this.csvData = this.csvData.filter(d => d.isManual == isManual);
    },
    add_manual_item() {
      this.csvData.unshift({
        client_sku: this.form.sku,
        competitor: this.form.competitor,
        competitor_sku: this.form.competitor_sku,
        competitor_url: this.form.url,
        matching_elasticity: this.elasticities.find(
          (e) => e.title == this.form.elasticity
        ).title,
        isManual: true,
        isUploaded: null,
        message: '',
      })
      this.reset_form()
    },
    reset_form() {
      this.form = {sku: '', competitor: undefined, competitor_sku: '', url: '', elasticity: undefined }
    },
    editItem (item) {
      this.editedIndex = this.csvData.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.csvData.indexOf(item)
      this.editedItem = Object.assign({}, item)
      // this assign the {} with the item bu as result create a clone of item
      this.dialogDelete = true
    },
    deleteMassConfirm() {
      this.empty_rows(true);
      this.dialogDeleteMass = false;
    },
    deleteSingleConfirm() {
      this.empty_rows(false);
      this.dialogDeleteSingle = false;
    },
    deleteItemConfirm () {
      this.csvData.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    save () {
      if (this.editedIndex > -1) {
        Object.assign(this.csvData[this.editedIndex], this.editedItem)
      } else {
        this.csvData.push(this.editedItem)
      }
      this.close()
    },
    // TODO column name praktiker should become dynamic on the template file, and also in the logic importing the files
    download_template() {
      const payload = {
        task: 'get_excel',
        payload: {
          client_id: this.$store.state.authModule.client.client_id,
          is_template: true,
        }
      }
      axios.post(AppUrl, payload, this.params()).then(res => {
        const path = res.data.payload.path;
        const filename = res.data.payload.filename;
        window.location.href = domain + path +'/'+ filename;
      }).catch( err => {
        console.log('Error', err);
      }).finally(() => {
      })
    },
    open_snackbar (message, color) {
      Object.assign(this.snackbar, { show: true, message, color })
    },
  },
  watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
};
</script>

<style scoped>

</style>
