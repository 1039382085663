<template >
    <v-container fluid class="text-center error-container">
        <h1>404</h1>
        <h5 class="mt-16"> Sorry, we could not find the page you are looking for.</h5>
        <v-btn to="matches" text="back to home" class="mt-8 button-full-elevated" size="x-large" rounded="lg"></v-btn>
    </v-container>
</template>

<style lang="scss">
// reseting background to hide the logo on the background
.error-container {
    z-index: 10;
    position: relative;
    height:100%;
    background: linear-gradient(200deg, hsl(175, 90%, 24%) 0%, hsl(182, 80%, 20%) 25%, hsl(185, 85%, 17%) 65%, hsl(175, 60%, 24%) 100%);

    h5 {
        font-size: 1.7em;
    }
    h1 {
        font-size: 15em;
        text-shadow: hsl(182, 80%, 2%) 1px 1px,
        hsl(182, 80%, 2%) 2px 2px,
        hsl(182, 80%, 2%) 3px 3px,
        hsl(182, 80%, 2%) 4px 4px,
        hsl(182, 80%, 2%) 5px 5px,
        hsl(182, 80%, 2%) 6px 6px,
        hsl(182, 80%, 2%) 7px 7px,
        hsl(182, 80%, 2.5%) 8px 8px,
        hsl(182, 80%, 2.5%) 9px 9px,
        hsl(182, 80%, 2.5%) 10px 10px,
        hsl(182, 80%, 3%) 11px 11px,
        hsl(182, 80%, 3%) 12px 12px,
        hsl(182, 80%, 3%) 13px 13px,
        hsl(182, 80%, 3%) 14px 14px,
        hsl(182, 80%, 3%) 15px 15px,
        hsl(182, 80%, 3%) 16px 16px,
        hsl(182, 80%, 3.5%) 17px 17px,
        hsl(182, 80%, 3.5%) 18px 18px,
        hsl(182, 80%, 3.5%) 19px 19px,
        hsl(182, 80%, 3.5%) 20px 20px,
        hsl(182, 80%, 4%) 21px 21px,
        hsl(182, 80%, 4%) 22px 22px,
        hsl(182, 80%, 4%) 23px 23px,
        hsl(182, 80%, 4%) 24px 24px,
        hsl(182, 80%, 4%) 25px 25px,
        hsl(182, 80%, 4%) 26px 26px,
        hsl(182, 80%, 4%) 27px 27px,
        hsl(182, 80%, 4.5%) 28px 28px,
        hsl(182, 80%, 4.5%) 29px 29px,
        hsl(182, 80%, 4.5%) 30px 30px,
        hsl(182, 80%, 5%) 31px 31px,
        hsl(182, 80%, 5%) 32px 32px,
        hsl(182, 80%, 5%) 33px 33px,
        hsl(182, 80%, 5%) 34px 34px,
        hsl(182, 80%, 5%) 35px 35px,
        hsl(182, 80%, 5%) 36px 36px,
        hsl(182, 80%, 5.5%) 37px 37px,
        hsl(182, 80%, 5.5%) 38px 38px,
        hsl(182, 80%, 5.5%) 39px 39px,
        hsl(182, 80%, 6%) 40px 40px,
        hsl(182, 80%, 6%) 41px 41px,
        hsl(182, 80%, 6%) 42px 42px,
        hsl(182, 80%, 6%) 43px 43px,
        hsl(182, 80%, 6%) 44px 44px,
        hsl(182, 80%, 6%) 45px 45px,
        hsl(182, 80%, 6%) 46px 46px,
        hsl(182, 80%, 6.5%) 47px 47px,
        hsl(182, 80%, 6.5%) 48px 48px,
        hsl(182, 80%, 6.5%) 49px 49px,
        hsl(182, 80%, 6.5%) 50px 50px,
        hsl(182, 80%, 7%) 51px 51px,
        hsl(182, 80%, 7%) 52px 52px,
        hsl(182, 80%, 7%) 53px 53px,
        hsl(182, 80%, 7%) 54px 54px,
        hsl(182, 80%, 7%) 55px 55px,
        hsl(182, 80%, 7%) 56px 56px,
        hsl(182, 80%, 7.5%) 57px 57px,
        hsl(182, 80%, 7.5%) 58px 58px,
        hsl(182, 80%, 7.5%) 59px 59px,
        hsl(182, 80%, 7.5%) 60px 60px,
        hsl(182, 80%, 8%) 61px 61px,
        hsl(182, 80%, 8%) 62px 62px,
        hsl(182, 80%, 8%) 63px 63px,
        hsl(182, 80%, 8%) 64px 64px,
        hsl(182, 80%, 8%) 65px 65px,
        hsl(182, 80%, 8%) 66px 66px,
        hsl(182, 80%, 8.5%) 67px 67px,
        hsl(182, 80%, 8.5%) 68px 68px,
        hsl(182, 80%, 8.5%) 69px 69px,
        hsl(182, 80%, 8.5%) 70px 70px,
        hsl(182, 80%, 9%) 71px 71px,
        hsl(182, 80%, 9%) 72px 72px,
        hsl(182, 80%, 9%) 73px 73px,
        hsl(182, 80%, 9%) 74px 74px,
        hsl(182, 80%, 9%) 75px 75px,
        hsl(182, 80%, 9%) 76px 76px,
        hsl(182, 80%, 9.5%) 77px 77px,
        hsl(182, 80%, 9.5%) 78px 78px,
        hsl(182, 80%, 9.5%) 79px 79px,
        hsl(182, 80%, 9.5%) 80px 80px,
        hsl(182, 80%, 10%) 81px 81px,
        hsl(182, 80%, 10%) 82px 82px,
        hsl(182, 80%, 10%) 83px 83px,
        hsl(182, 80%, 10%) 84px 84px,
        hsl(182, 80%, 10%) 85px 85px,
        hsl(182, 80%, 10%) 86px 86px,
        hsl(182, 80%, 10.5%) 87px 87px,
        hsl(182, 80%, 10.5%) 88px 88px,
        hsl(182, 80%, 10.5%) 89px 89px,
        hsl(182, 80%, 11%) 90px 90px,
        hsl(182, 80%, 11%) 91px 91px,
        hsl(182, 80%, 11%) 92px 92px,
        hsl(182, 80%, 11%) 93px 93px,
        hsl(182, 80%, 11%) 94px 94px,
        hsl(182, 80%, 11%) 95px 95px,
        hsl(182, 80%, 11%) 96px 96px,
        hsl(182, 80%, 11.5%) 97px 97px,
        hsl(182, 80%, 11.5%) 98px 98px,
        hsl(182, 80%, 11.5%) 99px 99px,
        hsl(182, 80%, 11.5%) 100px 100px,
        hsl(182, 80%, 12%) 101px 101px,
        hsl(182, 80%, 12%) 102px 102px,
        hsl(182, 80%, 12%) 103px 103px,
        hsl(182, 80%, 12%) 104px 104px,
        hsl(182, 80%, 12%) 105px 105px,
        hsl(182, 80%, 12%) 106px 106px,
        hsl(182, 80%, 12.5%) 107px 107px,
        hsl(182, 80%, 12.5%) 108px 108px,
        hsl(182, 80%, 12.5%) 109px 109px,
        hsl(182, 80%, 12.5%) 110px 110px,
        hsl(182, 80%, 13%) 111px 111px,
        hsl(182, 80%, 13%) 112px 112px,
        hsl(182, 80%, 13%) 113px 113px,
        hsl(182, 80%, 13%) 114px 114px,
        hsl(182, 80%, 13%) 115px 115px,
        hsl(182, 80%, 13.5%) 116px 116px,
        hsl(182, 80%, 13.5%) 117px 117px,
        hsl(182, 80%, 13.5%) 118px 118px,
        hsl(182, 80%, 13.5%) 119px 119px,
        hsl(182, 80%, 14%) 120px 120px,
        hsl(182, 80%, 14%) 121px 121px,
        hsl(182, 80%, 14%) 122px 122px,
        hsl(182, 80%, 14%) 123px 123px,
        hsl(182, 80%, 14%) 124px 124px,
        hsl(182, 80%, 14%) 125px 125px,
        hsl(182, 80%, 14%) 126px 126px,
        hsl(182, 80%, 14.5%) 127px 127px,
        hsl(182, 80%, 14.5%) 128px 128px,
        hsl(182, 80%, 14.5%) 129px 129px,
        hsl(182, 80%, 14.5%) 130px 130px,
        hsl(182, 80%, 15%) 131px 131px,
        hsl(182, 80%, 15%) 132px 132px,
        hsl(182, 80%, 15%) 133px 133px,
        hsl(182, 80%, 15%) 134px 134px,
        hsl(182, 80%, 15%) 135px 135px,
        hsl(182, 80%, 15%) 136px 136px,
        hsl(182, 80%, 15.5%) 137px 137px,
        hsl(182, 80%, 15.5%) 138px 138px,
        hsl(182, 80%, 15.5%) 139px 139px,
        hsl(182, 80%, 15.5%) 140px 140px,
        hsl(182, 80%, 16%) 141px 141px,
        hsl(182, 80%, 16%) 142px 142px,
        hsl(182, 80%, 16%) 143px 143px,
        hsl(182, 80%, 16%) 144px 144px,
        hsl(182, 80%, 16%) 145px 145px,
        hsl(182, 80%, 16%) 146px 146px,
        hsl(182, 80%, 16%) 147px 147px,
        hsl(182, 80%, 16%) 148px 148px,
        hsl(182, 80%, 16%) 149px 149px,
        hsl(182, 80%, 16%) 150px 150px,
        hsl(182, 80%, 16%) 151px 151px,
        hsl(182, 80%, 16%) 152px 152px,
        hsl(182, 80%, 16%) 153px 153px,
        hsl(182, 80%, 16.5%) 154px 154px,
        hsl(182, 80%, 16.5%) 155px 155px,
        hsl(182, 80%, 16.5%) 156px 156px,
        hsl(182, 80%, 16.5%) 157px 157px,
        hsl(182, 80%, 16.5%) 158px 158px,
        hsl(182, 80%, 16.5%) 159px 159px,
        hsl(182, 80%, 16.5%) 160px 160px,
        hsl(182, 80%, 17%) 161px 161px,
        hsl(182, 80%, 17%) 162px 162px,
        hsl(182, 80%, 17%) 163px 163px,
        hsl(182, 80%, 17%) 164px 164px,
        hsl(182, 80%, 17%) 165px 165px,
        hsl(182, 80%, 17%) 166px 166px,
        hsl(182, 80%, 17%) 167px 167px,
        hsl(182, 80%, 17%) 168px 168px,
        hsl(182, 80%, 17%) 169px 169px,
        hsl(182, 80%, 17%) 170px 170px,
        hsl(182, 80%, 17%) 171px 171px,
        hsl(182, 80%, 17%) 172px 172px,
        hsl(182, 80%, 17%) 173px 173px,
        hsl(182, 80%, 17%) 174px 174px,
        hsl(182, 80%, 17%) 175px 175px,
        hsl(182, 80%, 17%) 176px 176px,
        hsl(182, 80%, 17.5%) 177px 177px,
        hsl(182, 80%, 17.5%) 178px 178px,
        hsl(182, 80%, 17.5%) 179px 179px,
        hsl(182, 80%, 17.5%) 180px 180px,
        hsl(182, 80%, 18%) 181px 181px,
        hsl(182, 80%, 18%) 182px 182px,
        hsl(182, 80%, 18%) 183px 183px,
        hsl(182, 80%, 18%) 184px 184px,
        hsl(182, 80%, 18%) 185px 185px,
        hsl(182, 80%, 18%) 186px 186px,
        hsl(182, 80%, 18%) 187px 187px,
        hsl(182, 80%, 18%) 188px 188px,
        hsl(182, 80%, 18%) 189px 189px,
        hsl(182, 80%, 18%) 190px 190px,
        hsl(182, 80%, 18%) 191px 191px,
        hsl(182, 80%, 18%) 192px 192px,
        hsl(182, 80%, 18%) 193px 193px,
        hsl(182, 80%, 18%) 194px 194px,
        hsl(182, 80%, 18%) 195px 195px,
        hsl(182, 80%, 18.5%) 196px 196px,
        hsl(182, 80%, 18.5%) 197px 197px,
        hsl(182, 80%, 18.5%) 198px 198px,
        hsl(182, 80%, 18.5%) 199px 199px,
        hsl(182, 80%, 18.5%) 200px 200px,
        hsl(182, 80%, 19%) 201px 201px,
        hsl(182, 80%, 19%) 202px 202px,
        hsl(182, 80%, 19%) 203px 203px,
        hsl(182, 80%, 19%) 204px 204px,
        hsl(182, 80%, 19%) 205px 205px,
        hsl(182, 80%, 19%) 206px 206px,
        hsl(182, 80%, 19.5%) 207px 207px,
        hsl(182, 80%, 19.5%) 208px 208px,
        hsl(182, 80%, 19.5%) 209px 209px,
        hsl(182, 80%, 19.5%) 210px 210px;
    }
}
</style>