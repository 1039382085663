<template>
<!-- main content -->
  <v-card class="ma-5">
    <v-toolbar title="User Administration"> </v-toolbar>
    <v-container>
      <v-btn @click="openNewUserDialog" prepend-icon="mdi-account-plus">Add New User</v-btn>
      <v-text-field v-if="newUser.password" v-model="newUser.password" disabled label="New user's password" density="comfortable" class="mt-5"></v-text-field>
    </v-container>
    <v-container>
      <v-row justify="start" align="center">
        <v-col cols=4>
          <v-select
            v-model="selectedUserId"
            :items="users"
            label="Select existing user"
            item-title="fullname"
            item-value="user_id"
            @update:modelValue="selectUser"
          ></v-select>
        </v-col>
        <v-col>
          <div class="d-flex justify-start">
            <v-btn @click="saveOptions" :loading="save_loader" v-show="!!selectedUser" class="mx-3">Update user</v-btn>
            <v-btn v-show="!!selectedUser" @click="openResetPasswordDialog" class="mx-3">Reset Password</v-btn>
            <v-btn v-show="!!selectedUser" color="error" prepend-icon="mdi-account-minus" @click="openDeleteUserDialog" class="mx-3">Delete user</v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-list density="compact" style="column-count:4;" v-show="!!selectedUser">
      <v-list-item v-for="category in client_categories" :key="category.value" >
        <template v-slot:default="{ active }">
          <v-list-item-action start>
            <v-checkbox-btn v-model="selectedCategories" :label="category.title" :value="category.value" :input-value="active" ></v-checkbox-btn>
          </v-list-item-action>
        </template>
      </v-list-item>
    </v-list>
<!-- create new user dialog -->
  <v-dialog v-model="isNewUserDialogOpen">
    <v-card>
      <v-card-title>
        <span class="text-h5">Create New User</span>
      </v-card-title>
      <v-card-text>
        <v-text-field v-model="newUser.username" label="Username" disabled></v-text-field>
        <v-text-field v-model="newUser.email" label="Email"></v-text-field>
        <v-text-field v-model="newUser.firstName" label="First Name"></v-text-field>
        <v-text-field v-model="newUser.lastName" label="Last Name"></v-text-field>
        <!-- <div v-if="!!newUser.password">{{ newUser.password }}</div> -->
        <span class="text-warning" >{{ new_user_msg }}</span>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="cancelNewUser">Cancel</v-btn>
        <v-btn @click="createUser">Create</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- reset password dialog -->
  <v-dialog v-model="isResetPasswordDialogOpen">
    <v-card title="Reset Password">
      <v-card-text>
        <p>Are you sure you want to reset the password for user {{selectedUser.fullname}}?</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="cancelResetPassword">Cancel</v-btn>
        <v-btn @click="resetPassword">Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- delete user dialog -->
  <v-dialog v-model="delete_user_dialog.show">
    <v-card title="Delete User">
      <v-card-text>
        <v-row>
          <v-col sm="12" md="6" class="mt-3">
            <span> Type 'delete' to continue</span>
          </v-col>
          <v-col sm="12" md="6" >
            <v-text-field v-model="delete_user_dialog.delete_text" placeholder="delete" hide-details></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="cancel_delete_user">Cancel</v-btn>
        <v-btn color="white" class="bg-error" @click="delete_user" :disabled="delete_user_dialog.delete_text.toLowerCase() != 'delete'">Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
    </v-card>

<!-- review this -->
    <v-snackbar v-model="snackbar.show">
      {{ snackbar.message }}
    </v-snackbar>

</template>

<script>
import axios from "axios";
import { AppUrl } from "@/helpers/variables";
import JwtService from '@/assets/js/JwtService';
import { empty_object } from '@/assets/js/myFunctions';
export default {
  data() {
    return {
      selectedUserId: null,
      selectedUser: null,
      selectedCategories: [],
      pw_message: "",
      users: [
        // Your list of users
      ],
      isNewUserDialogOpen: false,
      isResetPasswordDialogOpen: false,
      newUser: {
        username: "",
        email: "",
        firstName: "",
        lastName: "",
        password: "",
      },
      new_user_msg: '',
      save_loader: false,
      delete_user_dialog: {
        show: false,
        delete_text: '',
      },
      snackbar:{
        show: false,
        message: '',
      },
    };
  },
  computed: {
    all_categories: function() {
      return this.$store.state.clientModule.categories;
    },
    client_categories: function() {
      let cats = [];
      this.all_categories.forEach(cat => {
        cats.push({value: cat.value, title:cat.text, checked:this.selectedCategories.includes(cat.value)});
      });

      return cats;
    },
  },
  methods: {
    async getUsers() {
      // return
      const payload = { task: "getUsers" };
      const params = { headers: { Authorization: `Bearer ${JwtService.getToken()}` } }
      const { data } = await axios.post(AppUrl, payload, params )
      this.users = data.users.map(u => ({...u, fullname: `${u.lastname} ${u.firstname}`}));
    },
    openNewUserDialog() {
      this.newUser = empty_object(this.newUser);
      this.isNewUserDialogOpen = true;
    },
    async createUser() {
      if (!this.ValidateEmail(this.newUser.email)){
        this.new_user_msg = 'Invalid Email';
        return;
      }
      // Perform user creation logic
      const payload = { task: "createUser", payload: {
        newUser: this.newUser
      } };
      const params = { headers: { Authorization: `Bearer ${JwtService.getToken()}` } }
      const { data } = await axios.post(AppUrl, payload, params )
      Object.assign(this.newUser, data.user);
      this.getUsers();
      this.isNewUserDialogOpen = false;
      this.snackbar.message = data.message
      this.snackbar.show = true
    },
    selectUser(id) {
      const userIndex = this.users.findIndex(u => u.user_id === id);
      this.selectedUser = this.users[userIndex];
      this.selectedCategories = this.selectedUser.permissions.map(p => p.category_id)
      this.pw_message = '';
    },
    cancelNewUser() {
      // Reset the fields and close the dialog
      this.newUser = empty_object(this.newUser);
      this.isNewUserDialogOpen = false;
      this.new_user_msg = '';
    },
    openResetPasswordDialog() {
      this.isResetPasswordDialogOpen = true;
    },
    async resetPassword() {
      const params = { headers: { Authorization: `Bearer ${JwtService.getToken()}` } }
      const payload = { task: "resetPassword", payload: {
        user: this.selectedUser
      } };
      const { data } = await axios.post(AppUrl, payload, params )
      Object.assign(this.selectedUser, {password: data.user.password})
      this.pw_message = data.message;
      this.isResetPasswordDialogOpen = false;
      this.snackbar.message = data.message
      this.snackbar.show = true
    },
    cancelResetPassword() {
      this.isResetPasswordDialogOpen = false;
    },
    async saveOptions() {
      this.save_loader = true
      const params = { headers: { Authorization: `Bearer ${JwtService.getToken()}` } }
      const payload = {
        task: 'savePermissionsCategories',
        payload: {
          user_id: this.selectedUser.user_id,
          category_ids: this.selectedCategories
        }
      }
      try {
        const { data } = await axios.post(AppUrl, payload, params )
        this.selectedUser.permissions = this.selectedCategories.map(p => ({category_id: p, can_edit: 1}))
        this.snackbar.message = 'User categories updated'
        this.snackbar.show = true
      } catch (error) {
        console.log('savePermissionsCategories - error', error)
      } finally {
        this.save_loader=false
      }
    },
    ValidateEmail(input) {
      const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      return input.match(validRegex);
    },
    openDeleteUserDialog () {
      this.delete_user_dialog.delete_text = '';
      this.delete_user_dialog.show = true;
    },
    cancel_delete_user() {
      this.delete_user_dialog.delete_text = '';
      this.delete_user_dialog.show = false;
    },
    async delete_user () {
      const params = { headers: { Authorization: `Bearer ${JwtService.getToken()}` } }
      const payload = { task: "deleteUser", payload: {
        user: this.selectedUser
      } };
      try {
        const { data } = await axios.post(AppUrl, payload, params )
        this.users = this.users.filter(u => u.user_id != data.deleted_user_id)
        this.selectedUser = null
        this.selectedUserId = null
        this.snackbar.message = data.message
        this.snackbar.show = true
      } catch (error) {
        console.log(error);
      } finally {
        this.delete_user_dialog.show = false ;
      }
    },
  },
  watch: {
    'newUser.email'(new_email) {
      this.newUser.username = new_email;
    },
  },
  mounted() {
    this.getUsers();
    this.$store.dispatch('clientModule/GET_CATEGORIES');
  },
};
</script>

<style scoped>

</style>