<template>
  <div>
    <v-btn block :loading="loading" size="x-large" append-icon="mdi-chevron-right" class=" button-full-elevated" rounded="lg" @click="submitFilters" :disabled="disable_button">
        Get match suggestions
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "products-filter",
  props: {
    disable_button: Boolean,
  },
  data: () => ({
    selected: {
      categories: [],
      titles: "",
      sku: '',
    },
  }),
  methods: {
    submitFilters () {
      this.$store.dispatch('competitorsModule/SubmitFilter', this.selected)
    }
  },
  computed: {
    loading() {
      return this.$store.state.competitorsModule.loading;
    }
  },
};
</script>
