// what does this do?
export const empty_object = (anObject) => {
  if (typeof anObject !== "object") throw new Error('Parameter is not an object!');

  const copy_obj = Object.assign({}, anObject);
  for (const key in copy_obj) {
    // delete obj.address[key];
    if (copy_obj[key] == null) continue;
    switch (typeof copy_obj[key]) {
      case "undefined":
        copy_obj[key] = undefined
        break;
      case "boolean":
        copy_obj[key] = false
        break;
      case "number" || "bigint":
        copy_obj[key] = -1
        break;
      case "string" || "symbol":
        copy_obj[key] = ''
        break;
      case "object":
        if(Array.isArray(copy_obj[key])){
          copy_obj[key] = [];
        } else if (copy_obj[key] instanceof Set ) {
          copy_obj[key] = new Set();
        } else if (copy_obj[key] instanceof Map) {
          copy_obj[key] = new Map();
        } else {
          copy_obj[key] = empty_object(copy_obj[key]);
        }
        break;
    }
  }
  return copy_obj
}
