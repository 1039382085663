<template>
  <v-card class="ma-5">
    <v-container class="pa-0">
      <v-data-table
        :headers="headers"
        :items="similar_data_with_class"
        :search="search"
        :hide-default-footer="!similar_data.length"
        :loading="overview_loading"
        items-per-page="25"
      >
      <template v-slot:top>
          <v-toolbar title="Matches overview">
            <v-spacer></v-spacer>
          <v-text-field
            label="Search"
            v-model="search"
            class="pa-3"
            prepend-inner-icon="mdi-magnify"
            style="width: 50px"
          />
            <!-- we are doing the button in this weird way to allow the tooltip / icon combo to work -->
            <v-tooltip>
              <template v-slot:activator="{ props }">
                <v-btn icon v-bind="props" @click="downloadContent" class="bg-quarternary" >
                  <v-icon size="large" color="primary">mdi-file-excel</v-icon>
                </v-btn>
              </template>
              <span>Download in excel format</span>
            </v-tooltip>
            <v-tooltip>
              <template v-slot:activator="{ props }">
                <v-btn icon v-bind="props" @click="refresh" class="bg-quarternary" >
                  <v-icon size="large" color="primary">mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Refresh results</span>
            </v-tooltip>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <v-hover>
            <template v-slot:default="{ isHovering, props }">
            <tr v-bind="props" :class="{ 'row-hovered': isHovering, 'row-disabled': !item.isActive}" @dblclick="dbl_click($event, item)">
              <td>{{item.client_sku}}</td>
              <td>{{item.competitor}}</td>
              <td>{{item.competitor_sku}}</td>
              <td class="overflowing-text--single">
                <v-tooltip>
                  <template v-slot:activator="{ props }" >
                      <a v-bind="props" :href="item.competitor_url" target="_blank" rel="noopener noreferrer">{{ item.competitor_url }}</a>
                  </template>
                  <span>{{item.competitor_url}}</span>
                </v-tooltip>
              </td>
              <td class="overflowing-text--single">
                <v-tooltip>
                  <template v-slot:activator="{ props }" >
                    <span v-bind="props"> {{item.product_name}} </span>
                  </template>
                  <span>{{item.product_name}}</span>
                </v-tooltip>
              </td>
              <td>{{item.category}}</td>
              <td>{{ formatPrice(item.price)}} </td>
              <td>{{ item.matching_elasticity}}</td>
              <td class="v-data-table-column--align-end d-flex align-center">
                <v-icon size="small" class="mr-2" @click="editItem(item)" color="primary"> mdi-pencil</v-icon>
                <v-icon size="small" @click="deleteItem(item)" color="error">mdi-delete</v-icon>
              </td>
            </tr>
            </template>
          </v-hover>
        </template>
      </v-data-table>
    </v-container>
  </v-card>

    <!-- edit item pop up -->
    <v-dialog v-model="edit_dialog" max-width="800px">
      <v-card title="Edit Match">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field v-model="editedItem.client_sku" label="Sku" disabled></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field v-model="editedItem.competitor_sku" label="Competitor Sku"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="editedItem.competitor_url" label="Competitor Url" disabled></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select v-model="editedItem.matching_elasticity" :items="elasticities" label="Matching Elasticity"></v-select>
              </v-col>
              <v-col cols="12" sm="12">
                <span class="text-warning">{{editedItemError }}</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="close">Cancel</v-btn>
          <v-btn :loading="save_btn_loading" @click="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete">
      <v-card title="Are you sure you want to delete this item?">
        <v-card-actions class="pt-10">
          <v-spacer></v-spacer>
          <v-btn @click="closeDelete">Cancel</v-btn>
          <v-btn :loading="delete_btn_loading" @click="deleteItemConfirm" color="error" class="bg-error">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import { AppUrl, domain } from "@/helpers/variables";
import axios from "axios";
import JwtService from "@/assets/js/JwtService";
import { formatNumber } from "@/helpers/commonFunctions.js"; // these also need to go to the methods

export default {
  name: "Matches-page",
  data() {
    return {
      delete_btn_loading: false,
      save_btn_loading: false,
      search: '',
      edit_dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        client_id: '',
        client_sku: '',
        competitor_sku: '',
        competitor_url: '',
        competitor_url_id: '',
        matching_elasticity: '',
        seller_id: '',
      },
      editedItemError: ''
    };
  },
  computed: {
    competitors() {
      return this.$store.state.competitorsModule.competitors;
    },
    client_name () {
      return this.$store.state.authModule.client.client_name;
    },
    client_id () {
      return this.$store.state.authModule.client.client_id;
    },
    headers() {
      return [
        {title: this.client_name + " SKU", key: "client_sku", sortable: true},
        {title: "Competitor", key: "competitor", sortable: true, align: "start"},
        {title: "Competitor SKU", key: "competitor_sku", sortable: true, align: "start"},
        {title: "URL", key: "competitor_url", sortable: true, align: "start"},
        {title: "Title", key: "product_name", sortable: true, align: "start"},
        {title: "Category", key: "category", sortable: true},
        {title: "Price", key: "price", sortable: true, align: "start"},
        {title: "Matching elasticity", key: "matching_elasticity", sortable: true, align: "start"},
        {title: "Actions", key: "actions", sortable: false, align: "end", width: "80"},
      ];
    },
    elasticities: function() {
      return this.$store.state.authModule.elasticities;
    },
    similar_data_with_class() {
      return this.similar_data.map((item) => ({
        classes: item.isActive ? "" : "text--disabled",
        ...item,
        matching_elasticity: this.getElasticity(item.matching_elasticity),
      }));
    },
    overview_loading: function() {
      return this.$store.state.clientModule.overview_loading;
    },
    overview_loaded: function() {
      return this.$store.state.clientModule.overview_loaded;
    },
    similar_data: {
      get () {
        return this.$store.state.clientModule.similar_data;
      },
      set (value) {
        this.$store.commit('clientModule/SET_SIMILAR_DATA', value);
      }
    },
  },
  methods: {
    params() {
      return { headers: { Authorization: `Bearer ${JwtService.getToken()}` } };
    },
    deleteItem(item) {
      this.editedIndex = this.similar_data.findIndex(x => x.client_sku === item.client_sku && x.competitor_sku === item.competitor_sku && x.competitor_url_id === item.competitor_url_id);
      this.editedItem = Object.assign({}, item); // this create a clone of item
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.delete_btn_loading = true;
      const payload = {
        task: "deleteSimilarMatch",
        payload: {
          client_id: this.client_id,
          item: this.editedItem,
        },
      };
      axios
        .post(AppUrl, payload, this.params())
        .then((res) => {
          this.similar_data = this.similar_data.filter(
            (sd) => !(
              sd.client_sku === this.editedItem.client_sku &&
              sd.competitor_sku === this.editedItem.competitor_sku &&
              sd.seller_id === this.editedItem.seller_id &&
              sd.matching_elasticity == this.editedItem.matching_elasticity
              )
          );
        })
        .catch((err) => {
          console.log("Error", err);
        })
        .finally(() => {
          this.delete_btn_loading = false;
          this.dialogDelete = false;
          this.refresh();
        });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, {});
        this.editedIndex = -1;
      });
    },
    editItem(item) {
      this.editedIndex = this.similar_data.findIndex(x => x.client_sku === item.client_sku && x.competitor_sku === item.competitor_sku && x.competitor_url_id === item.competitor_url_id);
      this.editedItem = Object.assign({}, item);
      this.edit_dialog = true;
    },
    close() {
      this.edit_dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, {});
        this.editedIndex = -1;
      });
    },
    save() {
      this.save_btn_loading = true;
      const payload = {client_sku: this.editedItem.client_sku, competitor_sku: this.editedItem.competitor_sku, competitor_url_id: this.editedItem.competitor_url_id,
      matching_elasticity: this.elasticities.find((x) => x.title == this.editedItem.matching_elasticity).key, seller_id: this.editedItem.seller_id};

      const request = {
        task: "saveSimilarChanges",
        payload: payload
      };
      axios
        .post(AppUrl, request, this.params())
        .then((res) => {
          this.save_btn_loading = false;
          if (res.data.payload.success == "success") {
            Object.assign(this.similar_data[this.editedIndex], this.editedItem);
            this.close();
            this.refresh();
          } else {
            this.editedItemError = res.data.payload.message;
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },
    downloadContent() {
      const payload = {
        task: "get_excel",
        payload: {
          client_id: this.client_id,
          is_template: false,
        },
      };
      axios
        .post(AppUrl, payload, this.params())
        .then((res) => {
        const path = res.data.payload.path;
        const filename = res.data.payload.filename;
          window.location.href = domain + path + "/" + filename;
        })
        .catch((err) => {
          console.log("Error", err);
        })
        .finally(() => {
        });
    },
    dbl_click(event, item) {
      this.editItem(item);
    },
    formatNumber,
    getElasticity(value) {
      let elas = "";
      if (this.elasticities.some(el => el.key === parseFloat(value))) {
          elas = this.elasticities.find((x) => x.key == parseFloat(value)).title;
        }
      return elas;
    },
    refresh() {
      this.$store.dispatch('clientModule/getSimilarData');
    },
    formatPrice(price) {
      let val = '';
      if (price > 0) {
        val = formatNumber(price) + ' €';
      }
        return val;
      }
  },
  mounted() {
    if (!this.overview_loaded) {
      this.$store.dispatch('clientModule/getSimilarData');
    }
  },
};
</script>

<style lang="scss">

</style>
