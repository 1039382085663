// Store module for category settings
/* eslint-disable */
import axios from "axios";
import { AppUrl } from "@/helpers/variables";
import JwtService from "@/assets/js/JwtService";

const state = {
  selectedCompetitor: '',
  selectedCategory: '',
  availableCategories: [],
  categorySpecs: [],
  loadingSpecifications: false,
  specificationsLoaded: false
};

const getters = {
};

const mutations = {
  setCompetitor(state, value) {
    state.selectedCompetitor = value;
  },
  setAvailableCategories(state, value) {
    state.availableCategories = value;
  },
  setCategory(state, value) {
    state.selectedCategory = value;
  },
  setCategorySpecs(state, value) {
    state.categorySpecs = value;
  },
  setLoadingSpecifications(state, value) {
    state.loadingSpecifications = value;
  },
  setSpecificationsLoaded(state, value) {
    state.specificationsLoaded = value;
  }
};

const actions = {
  getCategories(context) {
    const params = { headers: { Authorization: `Bearer ${JwtService.getToken()}` } }
    const payload = {
      task: "loadMyCategories",
      payload: {
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post(AppUrl, payload, params)
        .then(function (res) {
          if (res.data.categories != null) {
            context.commit('setAvailableCategories', res.data.categories)
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err)
        })
        .finally(() => {
        });
    });
  },
  getCategorySpecs(context) {
    context.commit('setLoadingSpecifications', true);
    context.commit('setSpecificationsLoaded', false);
    const params = { headers: { Authorization: `Bearer ${JwtService.getToken()}` } }
    const payload = {
      task: "loadSpecs",
      payload: {
        category_id: context.state.selectedCategory,
        domain_id: context.state.selectedCompetitor
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post(AppUrl, payload, params)
        .then(function (res) {
          if (res.data.specs != null) {
            context.commit('setCategorySpecs', res.data.specs)
          }
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err)
        })
        .finally(() => {
          context.commit('setLoadingSpecifications', false)
          context.commit('setSpecificationsLoaded', true)
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
